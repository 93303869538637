import { PortalNavbar } from "../components/portalNavbar"
import { CourseCard } from "../components/courseCard"

export function ProfessorPortal() {

    return (
        <div className="bg-[rgb(243,244,246)]">
            <PortalNavbar name='RAPANOEL Tsirinirina' role='Professeur' ></PortalNavbar>

            <div className="max-w-[1320px] mx-auto pb-12" id="dashboard">

                <div className="flex flex-col sm:flex-row mt-8 cursor-pointer m-auto sm:m-8 sm:mb-2 w-full">
                    <div className="ml-8 md:ml-0 text-[2.2rem] border-b w-full pt-[65px]">
                        Annonces
                    </div>


                </div>
                <div className="pl-4 mt-2 md:mt-0">
                    <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                        Ajouter
                    </div>


                    <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                        Modifier
                    </div>

                    <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                        Supprimer
                    </div>
                </div>

                <div className="mb-4 mb-[-65px] mx-8 ">
                    <table className="table-auto border mt-12 min-w-full">
                        <thead className="hidden md:table-header-group">
                            <tr>
                                <th className="px-4 py-2 border">ID</th>
                                <th className="px-4 py-2 border">Validité</th>
                                <th className="px-4 py-2 border">Public</th>
                                <th className="px-4 py-2 border">Texte</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="md:table-row">
                                <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                    <span className="md:hidden font-semibold">ID: </span>
                                    <span className="md:table-cell">1111</span>
                                </div>
                                <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                    <span className="md:hidden font-semibold">Validité: </span>
                                    <span className="md:table-cell">23/05/2023</span>
                                </div>
                                <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                    <span className="md:hidden font-semibold">Public: </span>
                                    <span className="md:table-cell">Mention gestion</span>
                                </div>
                                <div className="p-4 md:table-cell">
                                    <span className="md:hidden font-semibold">Texte: </span>
                                    <span className="md:table-cell">
                                        Comptabilité L1 / Prochain cours en videoconférence même horaire
                                    </span>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="my-12 px-8 w-full mb-[-65px]" id="course">


                    <div className="flex flex-col sm:flex-row mt-8 cursor-pointer m-auto sm:m-0 sm:mb-2 w-full">
                        <h2 className="text-[2.2rem] border-b w-full pt-[65px]">Cours</h2>

                    </div>
                    <div className="mb-8 mt-2 md:mt-0 flex justify-between flex-wrap">
                        <div className="flex flex-nowrap">
                            <div className="bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Ajouter
                            </div>
                            <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Modifier
                            </div>
                            <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Supprimer
                            </div>
                        </div>
                        <div className="ml-0 mt-4 md:mt-0 md:ml-4 bg-blue-600 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                            Lancer une visio conférence
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block ml-2 mb-0.5">
                                <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                            </svg>
                        </div>
                    </div>


                    <div className="w-full justify-between flex flex-col m-auto sm:flex-row sm:m-0 gap-4">

                        <CourseCard img='https://www.expert-comptable-tpe.fr/wp-content/uploads/2023/01/le-role-expert-comptable-ligne-optimisation-compta-1.jpg' level="L1" title="Comptabilité L1" format='pdf' link="pdf/Compta.pdf"></CourseCard>

                        <CourseCard img='https://ichef.bbci.co.uk/news/640/cpsprodpb/164EE/production/_109347319_gettyimages-611195980.jpg' level="L2" title="Mathématiques L2" format='pdf' link="pdf/Math.pdf"></CourseCard>

                        <CourseCard img="https://www.lemagdelentreprise.com/images/dossiers/2021-06/management-074216.jpg" level="L1" title="Management L1" format='pdf' link=""></CourseCard>

                        <CourseCard img="https://www.zdnet.com/a/img/2022/10/11/ed0ec52a-6b1b-4319-ae82-e0a81024a2f3/surfacelaptop5.jpg" level="L1" title="Technologie L1" format='video' link="https://www.youtube.com/watch?v=dg1esZRzEWk"></CourseCard>
                        {/*  fake cards here  */}
                        <CourseCard img='https://thumbs.dreamstime.com/b/main-tenant-l-ampoule-et-les-ic%C3%B4nes-de-commercialisation-num%C3%A9riques-technologie-d-innovation-affaires-sur-le-r%C3%A9seau-138534565.jpg' level="L1" title="Data L1" format='pdf' link="pdf/Compta.pdf"></CourseCard>

                        <CourseCard img="https://img.freepik.com/vecteurs-libre/concept-mot-science_23-2148533907.jpg" level="L1" title="Science L1" format='pdf' link=""></CourseCard>


                    </div>


                </div>

                
                <div className="my-12 mx-8 mb-[-65px]">
                    <h2 className="text-[2.2rem] w-full border-b pt-[65px] mb-2">Evaluation des connaissances</h2>
                    <div className="mb-8 mt-2 md:mt-0 flex justify-between flex-wrap">
                        <div className="flex flex-nowrap">
                            <div className="bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Ajouter
                            </div>
                            <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Modifier
                            </div>
                            <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Supprimer
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <table className="table-auto border mt-4 mx-0 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Date</th>
                                    <th className="px-4 py-2 border">Matière</th>
                                    <th className="px-4 py-2 border">Type</th>
                                    <th className="px-4 py-2 border">Lien</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Date: </span>
                                        <span className="md:table-cell">05/05/2023</span>
                                    </div>
                                    <div className="p-4 border-b border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Matière: </span>
                                        <span className="md:table-cell">Anglais</span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Type: </span>
                                        <span className="md:table-cell">Contrôle continu</span>
                                    </div>
                                    <div className="p-4 md:table-cell">
                                        <span className="md:hidden font-semibold">Lien: </span>
                                        <span className="text-white md:block text-center">
                                            <a href="pdf/Evaluation.pdf" target="_blank" className="w-auto inline-block px-4 py-1 block bg-blue-600 rounded-[10px]"> Consulter</a>
                                        </span>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                

                <div className="m-8 mt-8" id="forum">
                    <h2 className="text-[2.2rem] w-full border-b pt-[65px] mb-2">
                        Visio/Forum
                    </h2>
                    <div className="mb-8 mt-2 md:mt-0 flex justify-between flex-wrap">
                        <div className="flex flex-nowrap">
                            <div className="bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Créer
                            </div>
                            <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                                Rejoindre
                            </div>
                        </div>
                    </div>
                    <img src="pdf/forum.png" alt="" className="mt-8 max-w-full" />
                </div>
                

                <div className="m-8 mt-8" id="personnal">
                    <div className="">
                        <h2 className="text-[2.2rem] w-full border-b sm:m-0 sm:mb-2">
                            Espace personnel
                        </h2>
                        <a href="/employee/38990" className="mb-8 bg-blue-600 rounded-md text-white p-1 px-4 cursor-pointer inline-block">Consulter les données personnelles</a>
                    </div>



                    <div className="w-full mb-4">
                        <p className="mt-4 text-xl font-semibold">
                            Cahier de texte numérique
                        </p>

                        <table className="table-auto border mt-2 w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/cahier_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r  md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/cahier_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank" > Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden border-r font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/cahier_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>

                                </tr>
                            </tbody>
                        </table>


                        <p className="mt-12 text-xl font-semibold">
                            Suivi des présences
                        </p>

                        <table className="table-auto border mt-2 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/suivi_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/suivi_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank" > Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/suivi_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>

                                </tr>
                            </tbody>
                        </table>

                        <p className="mt-12 text-xl font-semibold">
                            Fiche de paie
                        </p>

                        <table className="table-auto border mt-2 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/paie_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/paie_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/paie_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>
    )
}