import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";

export default function ImportTables({}){
    const nameRef = useRef(null);
    const [data, setData] = useState(null);
    const [headers, setHeaders] = useState(null);
    const navigate = useNavigate();

    async function onFileSelected(event){
        if(event.target.files[0]){
            const file = event.target.files[0];
            const type = file.name.split(".").reverse()[0];

            if(type == "accdb"){
                const data = new FormData();
                data.append('file', file);
                data.append('dbName', "IUGM"); //TODO: get this programatically
                fetch("https://sparkso-universite.com:8081/api/handleAccessDatabase", {
                    method: "POST",
                    body: data
                }).then(() => {
                    navigate("/dragndrop");
                });

            } else {
                const fileUrl = URL.createObjectURL(file);
                const response = await fetch(fileUrl);
                const text = await response.text();
                const lines = text.split("\n");
                const _data = lines.map((line) => line.split(","));
                const headers = _data[0];
                setHeaders(headers);
                _data.shift();
                setData(_data);
            }
        } else {
            setHeaders(null);
            setData(null);
        }
    }

    async function validate(){
        const tables = [];
        await data.map((row) => {
            if(!tables.includes(row[3].toLowerCase().trim()) && row[3] != ""){
                tables.push(row[3].toLowerCase().trim());
            }
        });

        const dataTreated = data.map((row) => row.map(e => e.toLowerCase().trim()));

        const columnPerTables = {};

        dataTreated.forEach((row) => {
            tables.forEach((table) => {

                if(row.includes(table)){
                    if (!columnPerTables[table]){
                        columnPerTables[table] = {
                            amount: 0,
                            types: [],
                            names: [],
                            ids: []
                        };
                    }

                    columnPerTables[table].amount++;
                    columnPerTables[table].names.push(row[2]);
                    columnPerTables[table].ids.push(row[1]);
                    columnPerTables[table].types.push(row[4].toUpperCase());
                }
            })
        });
        
        fetch('https://sparkso-universite.com:8081/api/initializeDb', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                columnPerTables: JSON.stringify(columnPerTables),
                tables: JSON.stringify(tables),
                dbName: nameRef.current.value
            })
        });

        setTimeout(() => {
            navigate("/import-datas");
        }, 1500);
    }

    async function deleteDb(){
        fetch("https://sparkso-universite.com:8081/api/devDeleteDb", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dbName: nameRef.current.value
            })
        });
        window.location.reload();
    }

    return (
        <div className="p-4">
            <div className="text-black text-center mb-8">IMPORTER LES TABLES</div>
            <div className='flex justify-between items-center mb-8'>
                <input type="file" accept=".accdb ,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={onFileSelected}/>
                <input type="text" ref={nameRef} className="border border-gray-500 rounded-md w-[500px] px-4 py-1" placeholder="Entrez le nom de votre Base de données" defaultValue={"IUGM"}></input>
                <button className="bg-red-600 text-white w-[200px] h-[40px] rounded-md" onClick={deleteDb}>Supprimer</button>
                <button className="bg-green-600 text-white w-[200px] h-[40px] rounded-md" onClick={validate}>Valider</button>
            </div>

            {data && 
                <table>
                    <thead>
                        <tr>
                            {headers.map((title) =>
                                <th className="border border-black px-2 py-1 font-bold">{title}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => {
                            return (<tr>
                            { row.map((value) => 
                                <td className="border border-black px-2 py-1">{value}</td>
                            )}
                            </tr>)
                        })}
                    </tbody>
                </table>
            }
        </div>
    );
}
