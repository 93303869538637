export function CourseCard(props) {
    return (
        <>
            <div className="mt-4 flex border w-full lg:w-[200px]  flex-col overflow-hidden">
                <div className="w-[300px] h-[133px] bg-cover bg-center" style={{backgroundImage: `url('${props.img}')`}}></div>

                <p className="m-auto mt-4">
                    Niveau : {props.level}
                </p>
                <p className="m-auto mt-2">
                    Titre:  {props.title}
                </p>
                <p className="m-auto mt-2">
                    Format : {props.format == "pdf" ? <img src="/pdf.svg" width="24" className="inline-block"/> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block">
                    <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                    </svg>}
                </p>

                <a className="rounded-[10px] px-4 py-1 bg-blue-600 w-fit m-auto text-white cursor-pointer mt-4 mb-4" href={props.link} target="_blank">
                    Consulter
                </a>
            </div>
        </>
    )
}
