
export function EditableInformationBlocks(props) {
    return (
        <>
            <div className={`mt-2 flex-col sm:flex-row ${(props.employeeDegreeData || props.jobsData ? "w-[90%] lg:w-[70%] p-4" : "  w-[360px] lg:w-[700px]")} align-center justify-center items-center  border  m-auto mb-4 break-words `} >
                <div className="bg-[#bdd7ee]   ">
                    <p className="p-4 font-bold">
                        {props.title}
                    </p>
                </div>

                {props.personnalData && Object.keys(props.personnalData).map((key, index) => (

                    <>
                        <div className="flex justify-between gap-2  p-4">
                            <p className="font-bold">
                                {key}
                            </p>

                            <input type="text " id={key}
                                defaultValue={props.personnalData[key]}
                                className="w-[230px] border-b"
                            />

                        </div>
                    </>
                ))}

                {props.contactData && Object.keys(props.contactData).map((key, index) => (
                    <>
                        <div className="flex justify-between gap-2  p-4 break-words">
                            <p className=" font-bold">
                                {key}
                            </p>
                            <div className="break-words">
                                <input type="text" id={key}
                                    defaultValue={props.contactData[key]}
                                    size={props.contactData[key].lenght}
                                    className="w-[300px] border-b"

                                />

                            </div>


                        </div>
                    </>
                ))}

                {props.degreeData && Object.keys(props.degreeData).map((key, index) => (
                    <>
                        <div className="flex justify-between gap-2  p-4 break-words">
                            <p className=" font-bold">
                                {key}
                            </p>
                            <div className="break-words">
                                <input type="text" id={key}
                                    className="border-b" defaultValue={props.degreeData[key]}
                                />
                            </div>
                        </div>
                    </>
                ))}



                {props.employeeDegreeData && (
                    <>

                        <div className="overflow-x-auto">

                            <table className="table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2">Diplôme</th>
                                        <th className="px-4 py-2">Série</th>

                                        <th className="px-4 py-2">Numéro</th>
                                        <th className="px-4 py-2">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.employeeDegreeData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="border px-4 py-2">{item['degree_infos'].degree_level}</td>
                                            <td className="border px-4 py-2">{item['degree_infos'].degree_serial}</td>
                                            <td className="border px-4 py-2">{item['degree_infos'].degree_number}</td>
                                            <td className="border px-4 py-2">{item['degree_infos'].degree_date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>

                    </>
                )}

            </div>
        </>
    )
}