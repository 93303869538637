export async function getEmployeeData(employeeId) {
    return (fetch(`https://sparkso-universite.com:8081/getEmployee`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            emEmployeeId: employeeId
        })
    }).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    }));
}



export async function getEmployeeJobs(employeeId) {
    return (fetch(`https://sparkso-universite.com:8081/getEmployeeJob`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            emjEmployeeId: employeeId
        })
    }).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    }));
}


export async function getStudentData(studentId, linked) {
    return (fetch(`https://sparkso-universite.com:8081/api/getStudent`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: studentId,
            linked: linked
        })
    }).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    }));
}

export async function uploadImage(imagesFormData) {
    console.log(imagesFormData);

    const imagesResponse = await fetch(`https://sparkso-universite.com:8081/uploadImage`, {
        method: 'POST',
        body: imagesFormData
    });

    console.log(imagesResponse);
}

// export function updateStudentData(studentId, stfirstname, stLastname, stBirthdate, stBirthPlace, stSex, stPassport, stPassportDeliveryDate, stFather, stMother, stPhone, stMail, stAddress, stCursus, stSituation, stUniversityYear, stDegree, stDegreeSerie, stDegreeNumber, stDegreeDate) {

//     return (fetch(`https://sparkso-universite.com:8081/updateStudent/${studentId}`, {
//         method: "POST",
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//             stfirstname: stfirstname,
//             stLastname: stLastname,
//             stBirthdate: stBirthdate,
//             stBirthPlace: stBirthPlace,
//             stSex: stSex,
//             stPassport: stPassport,
//             stPassportDeliveryDate: stPassportDeliveryDate,
//             stFather: stFather,
//             stMother: stMother,
//             stPhone: stPhone,
//             stMail: stMail,
//             stAddress: stAddress,
//             stUniversityYear: stUniversityYear,
//             stCursus: stCursus,
//             stDegree: stDegree,
//             stDegreeSerie: stDegreeSerie,
//             stDegreeNumber: stDegreeNumber,
//             stDegreeDate: stDegreeDate,
//             stSituation: stSituation,
//         })

//     }).then((response) =>
//         console.log(response.body)
//     ).catch((err) => {
//         console.log(err);
//     }));
// }

export function updateEmployeeData(emEmployeeId, emfirstname, emLastname, emBirthdate, emBirthPlace, emSex, emPassport, emPassportDeliveryDate, emFather, emMother, emPhone, emMail, emAddress) {

    fetch(`https://sparkso-universite.com:8081/updateEmployee/${emEmployeeId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            emEmployeeId: emEmployeeId,
            emfirstname: emfirstname,
            emLastname: emLastname,
            emBirthdate: emBirthdate,
            emBirthPlace: emBirthPlace,
            emSex: emSex,
            emPassport: emPassport,
            emPassportDeliveryDate: emPassportDeliveryDate,
            emFather: emFather,
            emMother: emMother,
            emPhone: emPhone,
            emMail: emMail,
            emAddress: emAddress,
        })
    })
}

export function updateEmployeeJob(emjEmployeeId, emjJobId, emjJobYear, emjJobTitle, emjJobCompany, emjJobDIvision, emjJobDescription) {

    fetch(`https://sparkso-universite.com:8081/updateEmployeeJobs/${emjJobId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            emjEmployeeId: emjEmployeeId,
            emjJobId: emjJobId,
            emjJobYear: emjJobYear,
            emjJobTitle: emjJobTitle,
            emjJobCompany: emjJobCompany,
            emjJobDIvision: emjJobDIvision,
            emjJobDescription: emjJobDescription,
        })
    })

}

export function getDegree(degreeId) {
    return (fetch("https://sparkso-universite.com:8081/getDegree", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: degreeId
        })
    }).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    }));
}

export function getRecord(recordId) {
    return (fetch("https://sparkso-universite.com:8081/getRecord", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: recordId
        })
    }).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    }));
}

export function getEmployeeDegrees(employeeId) {
    return (fetch("https://sparkso-universite.com:8081/getEmployeeDegree", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: employeeId
        })
    }).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    }));
}


export function getStudentDegree(studentId) {
    return (fetch("https://sparkso-universite.com:8081/getStudentDegree", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: studentId
        })
    })).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    });
}

export function getCursus(studentId){
    return (fetch("https://sparkso-universite.com:8081/getCursus", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "cursus_student": studentId
        })
    })).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    });
}

export function addCursus(datas){
    console.log({
        body: JSON.stringify({
            "datas": datas
        })
    });
    return (fetch("https://sparkso-universite.com:8081/addCursus", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "datas": datas
        })
    })).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    });
}

export function removeCursus(cursus_id){
    return (fetch("https://sparkso-universite.com:8081/deleteCursus", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "cursus_id": cursus_id
        })
    })).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    });
}

export function getPage(){
    return (fetch("https://sparkso-universite.com:8081/api/getPage", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "dbName": "IUGM"
        })
    })).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    });
}

export function updateStudentData(datas){
    return (fetch("https://sparkso-universite.com:8081/api/updateStudent", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "address": datas.address,
            "mail": datas.mail,
            "phone": datas.phone,
            "id": datas.id
        })
    })).then((response) =>
        response.json()
    ).catch((err) => {
        console.log(err);
    });
}

export function adminUpdateStudentData(datas){
    return(fetch("https://sparkso-universite.com:8081/api/adminUpdateStudent", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "address": datas.address,
            "phone": datas.phone,
            "mail": datas.mail,
            "id": datas.id,
            "name": datas.name,
            "surname": datas.surname,
            "birthdate": datas.birthdate,
            "birthplace": datas.birthplace,
            "sex": datas.sex,
            "cni": datas.cni,
            "delivery": datas.delivery
        })
    })).then((response) => 
        response.json()
    ).catch((err) => {
        console.log(err);
    })
}