import { getStudentData, getStudentDegree, getCursus, getPage, updateStudentData, adminUpdateStudentData } from "../function";
import { InformationBlocks } from "../components/informationBlocks"
import { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import * as svg from 'save-svg-as-png';
import { toast } from "react-toastify";

export default function Student() {
    const [personnalData, setPersonnalData] = useState();
    const [cursusData, setCursusdata] = useState();
    const [degreeData, setDegreeData] = useState();
    const [contactData, setContactData] = useState();
    const [profilPic, setProfilPic] = useState();
    const [lessonsData, setLessonsData] = useState();
    const [scolarityFeesData, setScolarityFeesData] = useState();
    const [connectedAs, setConnectedAs] = useState(null);
    const [linked, setLinked] = useState(null);
    const [page, setPage] = useState(null);
    const addressRef = useRef(null); 
    const phoneRef = useRef(null); 
    const mailRef = useRef(null); 
    const idRef = useRef(null);
    const nameRef = useRef(null);
    const surnameRef = useRef(null);
    const birthdateRef = useRef(null);
    const birthplaceRef = useRef(null);
    const sexRef = useRef(null);
    const cniRef = useRef(null);
    const deliveryRef = useRef(null);

    let { id } = useParams();

    useEffect(() => {
        getPage("etudiants").then((data) => {
            if (localStorage.getItem("accountType")) {
                setConnectedAs(localStorage.getItem("accountType"));
            }
            data = data.map((e) => JSON.parse(e.attribut1) );
            setPage(data);

            const linked = [];
            data.forEach((block) => {
                block.forEach((table) => {
                    linked.push(table.fields[0].attribut4);
                })
            });

            getStudentData(id, linked).then((data) => {
                let { student, linked, degrees, fees } = data;
                console.log(student);
                student = student[0];
                setLinked(linked);
                setDegreeData(degrees);
                setScolarityFeesData(fees);
                setPersonnalData({ 'Nom': student.attribut002, 'Prenom': student.attribut003, 'Date de naissance': student.attribut009, "Lieu de naissance": student.attribut010, "Sexe": student.attribut013, "CNI/Passeport": student.attribut022, "Delivery Date": student.attribut016, "Père": "", "Mère": "", "Photo": student.attribut017 })
                setContactData({ "Téléphone": student.attribut023, "Email": student.attribut015, "Adresse": student.attribut011 });
                // setCursusdata({ "Année universitaire": data.stUniversityYear, "Niveau": data.stCursus, "Parcours": "", "Statut": data.stSituation });
                // setLessonsData({ "Nombre de cours": data.stLessons, "Présent": data.stLessonsPresent, "Absent": data.stLessonsNotPresent, "Excusé": data.stLessonsExcuse })
            });
        });
        
        getStudentDegree(id).then((response) => {
            const datas = [];
            response.degrees.forEach((degree) => {
                const record = response.records.find((e) => { return (e.degree_infos.degree_id == degree.id) });
                datas.push({
                    "Diplome": record.degree_infos.degree_level,
                    "Série": "S",
                    "Numéro": record.degree_infos.degree_id,
                    "Date": record.year
                });
            })
            setDegreeData(datas);
            // console.log(degreeData)
        });

        getCursus(id).then((response) => {
            // console.log(response);
            setCursusdata(response);
        });
    }, [])

    async function saveUpdatedDatas(){
        const address = addressRef.current.value; 
        const phone = phoneRef.current.value; 
        const mail = mailRef.current.value; 

        updateStudentData({"id": id, "address": address, "phone": phone, "mail": mail}).then((response) => {
            toast.success("Données mises à jour");
        });
    }

    async function downloadQrCode(){
        console.log(svg);
        svg.saveSvgAsPng(document.getElementById("qrcode"), `${id}.png`, {scale: 10});
    }

    async function adminUpdateStudent(){
        const address = addressRef.current.value;
        const phone = phoneRef.current.value;
        const mail = mailRef.current.value;
        const id = idRef.current.value;
        const name = nameRef.current.value;
        const surname = surnameRef.current.value;
        const birthdate = birthdateRef.current.value;
        const birthplace = birthplaceRef.current.value;
        const sex = sexRef.current.value;
        const cni = cniRef.current.value;
        const delivery = deliveryRef.current.value;

        adminUpdateStudentData({"address": address, "phone": phone, "mail": mail, "id": id, "name": name, "surname": surname, "birthdate": birthdate, "birthplace": birthplace, "sex": sex, "cni": cni, "delivery": delivery}).then((response) => {
            toast.success("Données mises à jour");
        });
    }

    return (
        <>
        <div className="hidden w-12 h-12"><QRCode id="qrcode" value={`https://sparkso-universite.com/mahajanga/IUGM/${id}`}></QRCode></div>
        {personnalData &&
            <div className="bg-[#44546a] w-full h-[150px] flex items-center relative mb-8 md:mb-0">

                <div className="max-w-[1300px] mx-auto w-full flex items-center h-full">
                    <div className="flex-1 h-full">
                        {(connectedAs && personnalData) && 
                            <img className="h-full absolute left-62" src={`/photos/${personnalData["Photo"]}.jpg`}></img>
                        }
                        <p className="text-white text-center text-xl md:text-3xl uppercase flex-1 pt-5">
                            Université MAHAJANGA
                        </p>
                        <p className="text-gray-400 text-center text-md mt-4 md:mt-0 md:text-xl">{personnalData && <>{personnalData["Prenom"]} <span className="capitalize">{personnalData["Nom"]}</span></>}</p>
                        <p className="text-gray-400 text-center text-md mt-4 md:mt-0 md:text-xl">Licence 1</p>
                    </div>
                </div>
                <Link to="/mahajanga/scan" className="absolute left-5 md:text-white text-black z-40 -bottom-8 md:bottom-7 underline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-4 h-4 mb-0.5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
Retour</Link>
            <div className="absolute right-8 h-full hidden sm:block"><img className="h-full py-2 w-[85px] h-[80px] sm:h-full sm:w-auto" src="/logo2rond.png" /></div>
            </div>
        }

            { (!connectedAs && personnalData) && 
                <div className="text-center relative max-w-[60%] mx-auto">
                    <img src="/IMG_6446.jpg" className="shadow shadow-2xl"/>
                    <img src={`/photos/${personnalData["Photo"]}.jpg`} alt="" className="-translate-x-1/2 -translate-y-1/2 w-[23%] absolute top-[53%] left-1/2" />
                    <div className="font-comic-sans absolute bottom-8 md:bottom-20 right-2 md:right-8 md:text-2xl text-sm capitalize text-blue-600">{id}</div>
                    <div className="font-comic-sans absolute bottom-0 md:bottom-4 right-2 md:right-8 md:text-2xl text-sm capitalize font-extrabold">{personnalData["Nom"]}</div>
                    <div className="font-comic-sans absolute bottom-4 md:bottom-12 right-2 md:right-8 md:text-2xl text-sm capitalize">{personnalData["Prenom"]}</div>
                </div>
            }

            {(connectedAs && personnalData) && 
                <>
                    <div className="flex max-w-[100vw] md:max-w-[1400px] mx-auto">
                        <div className="flex justify-between flex-wrap w-full">
                            <div className={`mb-8 mt-6 border break-words rounded-[10px] overflow-hidden mx-auto md:mx-0 w-[47%] relative`}>
                                <div className="capitalize bg-[#bdd7ee] border-b border-[rgba(0,0,0,.175)] mb-4">
                                    <p className="p-4 text-[1.5rem] uppercase">ETUDIANT(E)</p>
                                </div>
                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">ID</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={id} ref={idRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{id}</div>
                                    }
                                </div>

                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">Nom</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={personnalData.Nom} ref={nameRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{personnalData.Nom}</div>
                                    }
                                </div>
                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">Prénom</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={personnalData.Prenom} ref={surnameRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{personnalData.Prenom}</div>
                                    }
                                </div>
                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">Date de naissance</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={personnalData["Date de naissance"].split("GMT")[0]} ref={birthdateRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{personnalData["Date de naissance"].split("GMT")[0]}</div>
                                    }
                                </div>
                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">Lieu de naissance</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={personnalData["Lieu de naissance"]} ref={birthplaceRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{personnalData["Lieu de naissance"]}</div>
                                    }
                                </div>
                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">Sexe</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={personnalData.Sexe} ref={sexRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{personnalData.Sexe}</div>
                                    }
                                </div>
                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">CNI / Passeport</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={personnalData["CNI/Passeport"]} ref={cniRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{personnalData["CNI/Passeport"]}</div>
                                    }
                                </div>
                                <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                    <div className="capitalize min-w-[40%] font-extrabold">Délivré(e) le</div>
                                    {(connectedAs && connectedAs == "admin") &&
                                        <input type="text" className="border border-black px-2 w-full" defaultValue={personnalData["Delivery Date"].split("GMT")[0]} ref={deliveryRef}/>
                                    }
                                    {connectedAs != "admin" &&
                                        <div className="flex-1 text-right md:text-left">{personnalData["Delivery Date"].split("GMT")[0]}</div>
                                    }
                                </div>

                                {(connectedAs && connectedAs == "admin") &&
                                    <div className="flex justify-between pt-4">
                                        <div className="bg-blue-500 px-2 py-1 rounded-md ml-2 hover:scale-95 cursor-pointer" onClick={downloadQrCode}>Télécharger le QR Code de l'étudiant</div>
                                        <div className="bg-green-500 px-2 py-1 rounded-md mr-2 hover:scale-95 cursor-pointer" onClick={adminUpdateStudent}>Enregistrer les modifications</div>

                                    </div>
                                }
                            </div>

                            <div className="flex flex-col w-[47%]">

                                <div className={`mb-8 mt-6 border break-words rounded-[10px] overflow-hidden mx-auto md:mx-0 w-full`}>
                                    <div className="capitalize bg-[#bdd7ee] border-b border-[rgba(0,0,0,.175)] mb-4">
                                        <p className="p-4 text-[1.5rem] uppercase">DIPLOMES / CERTIFICATIONS</p>
                                    </div>
                                    
                                    <div className="overflow-x-auto gap-4 pl-4">
                                        <tr className="pl-6">
                                            <th className="p-2">Diplôme</th>
                                            <th className="p-2">Série</th>
                                            <th className="p-2">Date</th>
                                        </tr>
                                        <tr className="">
                                            {degreeData && degreeData.degrees.map((degree) => {
                                                return (
                                                    <>
                                                        <td className="p-2 text-center">{degreeData.degreeData[0].find((e) => e.attribut001 == degree.attribut002).attribut002}</td>
                                                        <td className="p-2 text-center">{degreeData.degreeData[0].find((e) => e.attribut001 == degree.attribut002).attribut003}</td>
                                                        <td className="p-2 text-center">{degree.attribut004}</td>
                                                    </>
                                                );
                                            }) 
                                            }
                                        </tr>
                                    </div>
                                </div>

                                <div className={`mb-8 mt-6 border break-words rounded-[10px] overflow-hidden mx-auto md:mx-0 w-full`}>
                                    <div className="capitalize bg-[#bdd7ee] border-b border-[rgba(0,0,0,.175)] mb-4">
                                        <p className="p-4 text-[1.5rem] uppercase">FRAIS DE SCOLARITÉ</p>
                                    </div>
                                    <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                        <div className="min-w-[40%] font-extrabold">{scolarityFeesData && (scolarityFeesData.length ? "A jour" : "En attente de règlement")}</div>
                                    </div>
                                </div>

                                {contactData &&
                                
                                    <div className={`mb-8 mt-6 border break-words rounded-[10px] overflow-hidden mx-auto md:mx-0 w-full`}>
                                        <div className="capitalize bg-[#bdd7ee] border-b border-[rgba(0,0,0,.175)] mb-4">
                                            <p className="p-4 text-[1.5rem] uppercase">CONTACT</p>
                                        </div>
                                        <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                            <div className="capitalize min-w-[40%] font-extrabold">Téléphone</div>
                                            {(connectedAs && (connectedAs == "student" && (localStorage.getItem("student") == id)) || connectedAs == "admin") ? 
                                                <input ref={phoneRef} type="text" defaultValue={contactData["Téléphone"]} className="px-2 border border-black flex-1 text-right md:text-left"/>
                                                : 
                                                <div className="flex-1 text-right md:text-left">{contactData["Téléphone"]}</div>
                                            }
                                        </div>
                                        <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                            <div className="capitalize min-w-[40%] font-extrabold">Email</div>
                                            {(connectedAs && (connectedAs == "student" && localStorage.getItem("student") == id) || connectedAs == "admin") ? 
                                                <input ref={mailRef} type="text" defaultValue={contactData["Email"]} className="px-2 border border-black flex-1 text-right md:text-left"/>
                                                : 
                                                <div className="flex-1 text-right md:text-left">{contactData["Email"]}</div>
                                            }

                                            
                                        </div>
                                        <div className="flex justify-between gap-2 px-4 md:text-left mb-4">
                                            <div className="capitalize min-w-[40%] font-extrabold">Adresse</div>
                                            {(connectedAs && (connectedAs == "student" && localStorage.getItem("student") == id) || connectedAs == "admin") ? 
                                                <input ref={addressRef} type="text" defaultValue={contactData["Adresse"]} className="px-2 border border-black flex-1 text-right md:text-left"/>
                                                : 
                                                <div className="flex-1 text-right md:text-left">{contactData["Adresse"]}</div>
                                            }
                                        </div>
                                        {(connectedAs && connectedAs == "student" && localStorage.getItem("student") == id) &&
                                            <div className="bg-green-500 py-1 px-2 rounded-md cursor-pointer hover:scale-95 inline-block float-right mr-2 mb-2" onClick={saveUpdatedDatas}>Sauvegarder</div>
                                        }
                                    </div>
                                }

                            </div>

                        </div>
                    </div>                            
                </>
            }
            {(connectedAs && page && linked) && 
                <>
                    <div className="flex justify-between flex-wrap max-w-[100vw] md:max-w-[1400px] mx-auto">
                    {
                        page && page.map((block) => {
                            return block.map((datas) => {
                                const linkedDatas = linked.filter((e) => e?.table == datas.title)[0];
                                return(
                                    <div className={`mb-8 mt-6 border break-words rounded-[10px] overflow-hidden mx-auto md:mx-0 ${datas.fullRow ? "w-full" : "w-[47%] inline-block"}`}>
                                        <div className="capitalize bg-[#bdd7ee] border-b border-[rgba(0,0,0,.175)] mb-4">
                                            <p className="p-4 text-[1.5rem] uppercase">{datas.title.replaceAll("_", " ")}</p>
                                        </div>
                                        { datas.fields.map((data, index) => {
                                            return (<div className="flex justify-between gap-2 px-4 md:text-left mb-4"><div className="capitalize min-w-[40%] font-extrabold">{data.attribut3.replace("_", " ")}</div><div className="flex-1 text-right md:text-left">{linkedDatas ? linkedDatas.datas[index] : "Pas renseigné"}</div></div>)
                                        })}
                                    </div>
                                );
                            })
                        })
                    }
                    </div>
                </>
            }

        </>
    )
}