import './App.css';
import React from 'react';
import { Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Student from './pages/student';
import EditStudent from './pages/editStudent';
import Degree from './pages/degree';
import Record from './pages/record';
import Login from './pages/login';
import Employee from './pages/employee';
import Scan from './pages/scan';
import EditEmployee from './pages/editEmployee';
import { ProfessorPortal } from './pages/professorPortal';
import { StudentPortal } from './pages/studentPortal';
import { AdminPortal } from './pages/adminPortal';
import Landing from './pages/landing';
import ImportTables from './pages/SuperAdmin/importTables';
import ImportDatas from './pages/SuperAdmin/importDatas';
import CreateVisual from './pages/SuperAdmin/createVisual';
import NewStudent from './pages/SuperAdmin/newStudent';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

function App() {
  return (

    <BrowserRouter>
        <ToastContainer/>
        <Routes>
          <Route path="/" element={<Scan />}></Route>
          <Route path='/login' element={<Login></Login>}></Route>
          <Route path="/mahajanga/scan" element={<Scan />}></Route>
          <Route path='/mahajanga/IUGM/:id' element={<Student />} />
          <Route path='/:id' element={<Student />} />
          <Route path='/teacher/:id' element={<EditStudent />} />
          <Route path='/diplome/:id' element={<Degree />} />
          <Route path='/notes/:id' element={<Record />} />
          <Route path="/employee/:id" element={<Employee></Employee>}></Route>
          <Route path='/editEmployee/:id' element={<EditEmployee></EditEmployee>} ></Route>
          <Route path='/professorPortal/' element={<ProfessorPortal></ProfessorPortal>} ></Route>
          <Route path='/studentPortal/' element={<StudentPortal></StudentPortal>} ></Route>
          <Route path='/adminPortal' element={<AdminPortal></AdminPortal>} ></Route>

          {/* Super Admin */}
          <Route path='/import' element={<ImportTables></ImportTables>}></Route>
          <Route path='/dragndrop' element={<CreateVisual></CreateVisual>}></Route>
          <Route path="/import-datas" element={<ImportDatas></ImportDatas>}></Route>
          <Route path="/newStudent" element={<NewStudent></NewStudent>}></Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
