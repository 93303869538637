import { useRef, useEffect, useState } from "react";
import parse from 'html-react-parser';
import Sidebar from "../../components/sidebar";
import Canvas from "../../components/canvas";
import {DndContext, DragOverlay} from '@dnd-kit/core';

export default function CreateVisual({}){
    const textRef = useRef(null);
    const mappingRef = useRef(null);
    const [html, setHtml] = useState(null);
    const [tables, setTables] = useState(null);
    const [active, setActive] = useState(null);
    const [elements, setElements] = useState([]);

    function submit(){
        console.log(JSON.stringify({
            elements: elements
        }));
        fetch('https://sparkso-universite.com:8081/api/createPage', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                elements: elements,
                dbName: "IUGM" // TODO: Get this programmatically
            })
        });
    }

    useEffect(() => {
        fetchDatabase();
    }, []);

    async function fetchDatabase(){
        const response = await fetch('https://sparkso-universite.com:8081/api/getDatas', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dbName: "IUGM" // TODO: Get this programmatically
            })
        }).then((response) => {
            return response.json();
        });

        const tables = [];
        mappingRef.current = response.mapping;
        await response.mapping.forEach((e) => {
            if (!tables.includes(e.attribut4)) {
                tables.push(e.attribut4);
            }
        });
    
        setTables(tables);
    }

    function handleDragOver(e) {
    }
      
    function handleDragEnd(e) {
        const { over } = e;
        
        if (!over){
            setActive(null);
        } else {
            const fields = mappingRef.current.filter((e) => e.attribut4 == active);
            setElements([...elements, {fields: fields, title: active, fullRow: false, table: false}]);
            setActive(null);
        }
    }

    function handleDragStart(e) {
        const { active } = e;
        setActive(active.id);
    }

    useEffect(() => {

    }, [elements]);

    return (
        <div className="p-4 h-screen">
            <DndContext
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                >
                <DragOverlay dropAnimation={false}>
                    {/* TODO: COPY ELEMENT AND PUT IT THERE */}
                    <div className="w-[200px] h-[40px]">TODO createVisual</div>
                </DragOverlay>
                <div className="flex w-full h-full items-center gap-12">
                    { tables && 
                        <Sidebar titles={tables}/>
                    }
                    <div className="flex-1 h-full items-center flex-col p-12">
                        <div className="flex justify-end mb-4">
                            <div className="transition-200 hover:scale-95 cursor-pointer bg-green-600 text-white px-4 py-1 rounded-md" onClick={submit}>Valider</div>
                        </div>

                        <Canvas elements={elements} setElements={setElements} />
                    </div>
                </div>
            </DndContext>
        </div>
    );

}