import { PortalNavbar } from "../components/portalNavbar"
import { CourseCard } from "../components/courseCard"


export function StudentPortal() {

    return (
        <div className="bg-[rgb(243,244,246)]">
            <PortalNavbar name="ANDRIA TSILAVINAMIARANTSOA" role="Etudiant(e) Licence 1  Mention Gestion" ></PortalNavbar>

            <div className="max-w-[1320px] mx-auto pb-12" id="dashboard">

                <div className="flex flex-col sm:flex-row mt-8 cursor-pointer m-auto w-fit sm:m-8 text-[2.2rem] w-full">
                    <div className="md:ml-0 pl-4 w-full border-b border-b-1">
                        Annonces
                    </div>

                    {/* <div className="ml-4 bg-black rounded-md text-white p-1 px-4 cursor-pointer ">
                        Ajouter
                    </div>


                        <div className="ml-4 bg-blue-500 text-[18px] rounded-md text-white p-1 px-4 cursor-pointer flex items-center hover:scale-105 transition duration-100">
                            Modifier
                        </div>

                    <div className="ml-4 bg-black rounded-md text-white p-1 px-4 cursor-pointer">
                        Supprimer
                    </div> */}

                </div>


                <div className="max-w-[1300px] mx-auto mb-4">
                    <table className="table-auto border mt-12 min-w-full">
                        <thead className="hidden md:table-header-group">
                            <tr>
                                <th className="px-4 py-2 border text-center">ID</th>
                                <th className="px-4 py-2 border text-center">Validité</th>
                                <th className="px-4 py-2 border text-center">Public</th>
                                <th className="px-4 py-2 border text-center">Texte</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="md:table-row">
                                <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                    <span className="md:hidden font-semibold">ID: </span>
                                    <span className="md:table-cell">1111</span>
                                </div>
                                <div className="p-4 border-b md:border-b-0 md:table-cell">
                                    <span className="md:hidden font-semibold">Validité: </span>
                                    <span className="md:table-cell">23/05/2023</span>
                                </div>
                                <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                    <span className="md:hidden font-semibold">Public: </span>
                                    <span className="md:table-cell">Mention gestion</span>
                                </div>
                                <div className="p-4 md:table-cell">
                                    <span className="md:hidden font-semibold">Texte: </span>
                                    <span className="md:table-cell">
                                        Comptabilité L1 / Prochain cours en videoconférence même horaire
                                    </span>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div className="mt-12" id="course">


                    <div className="flex flex-col sm:flex-row mt-8 cursor-pointer m-auto sm:m-8 md:ml-0 ml-4">
                        <h2 className="text-[2.2rem] border-b w-full pt-[65px] pl-8">Cours</h2>

                        {/* <div className="ml-4 bg-black rounded-md text-white p-1 px-4 cursor-pointer ">
                            Ajouter
                        </div>


                        <div className="ml-4 bg-black rounded-md text-white p-1 px-4 cursor-pointer">
                            Modifier
                        </div>

                        <div className="ml-4 bg-black rounded-md text-white p-1 px-4 cursor-pointer">
                            Supprimer
                        </div>

                        <div className="ml-4 bg-black rounded-md text-white p-1 px-4 cursor-pointer">
                            lancer une visio conférence
                        </div> */}
                    </div>


                    <div className="w-full gap-6 flex flex-col m-auto sm:flex-row sm:m-0 px-8">

                        <CourseCard img='https://www.expert-comptable-tpe.fr/wp-content/uploads/2023/01/le-role-expert-comptable-ligne-optimisation-compta-1.jpg' level="L1" title="comptabilité L1" format='pdf' link="pdf/Compta.pdf"></CourseCard>

                        <CourseCard img="https://www.lemagdelentreprise.com/images/dossiers/2021-06/management-074216.jpg" level="L1" title="Management L1" format='pdf' link=""></CourseCard>

                        <CourseCard img="https://www.zdnet.com/a/img/2022/10/11/ed0ec52a-6b1b-4319-ae82-e0a81024a2f3/surfacelaptop5.jpg" level="L1" title="Technologie L1" format='video' link="https://www.youtube.com/watch?v=dg1esZRzEWk"></CourseCard>

                        {/*  fake cards here  */}
                        <CourseCard img='https://thumbs.dreamstime.com/b/main-tenant-l-ampoule-et-les-ic%C3%B4nes-de-commercialisation-num%C3%A9riques-technologie-d-innovation-affaires-sur-le-r%C3%A9seau-138534565.jpg' level="L1" title="data L1" format='pdf' link="pdf/Compta.pdf"></CourseCard>

                        <CourseCard img="https://img.freepik.com/vecteurs-libre/concept-mot-science_23-2148533907.jpg" level="L1" title="Science L1" format='pdf' link=""></CourseCard>

                        <CourseCard img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd7iXVwhwY6i6rY21dwdjeEkgYY4iRmoy42B2eIWTp&s" level="L1" title="Engineering L1" format='pdf' link="https://www.youtube.com/watch?v=dg1esZRzEWk"></CourseCard>

                    </div>


                </div>

                
                <div className="mt-8 m-8">
                    <h2 className="text-[2.2rem] border-b w-full pt-[65px]">Evaluation des connaissances</h2>

                    <div className="mb-4">
                        <table className="table-auto border mt-12 w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Date</th>
                                    <th className="px-4 py-2 border">Matière</th>
                                    <th className="px-4 py-2 border">Type</th>
                                    <th className="px-4 py-2 border">Lien</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Date: </span>
                                        <span className="md:table-cell">05/05/2023</span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Matière: </span>
                                        <span className="md:table-cell">Anglais</span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Type: </span>
                                        <span className="md:table-cell">Contrôle continu</span>
                                    </div>
                                    <div className="p-4 md:table-cell text-center">
                                        <span className="md:hidden font-semibold">Lien: </span>
                                        <span className="block">
                                            <a href="pdf/Evaluation.pdf" target="_blank" className="rounded-[10px] px-4 py-1 bg-blue-600 w-fit m-auto text-white cursor-pointer mt-4 mb-4"> Consulter</a>
                                        </span>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="m-8 mt-8" id="forum">
                    <h2 className="text-[2.2rem] border-b w-full pt-[65px]">
                        Visio/Forum
                    </h2>

                    <img src="pdf/forum.png" alt="" className="mt-8 max-w-full" />
                </div>
                

                <div className="m-8 mt-8" id="personnal">
                    <div className="">
                        <h2 className="text-[2.2rem] border-b w-full pt-[65px] mb-2">
                            Espace personnel
                        </h2>
                        <a href="/43198" className="mb-8 bg-blue-600 rounded-md text-white p-1 px-4 cursor-pointer inline-block">Consulter les données personnelles</a>
                    </div>



                    <div className="mb-4">
                        <p className="mt-4 text-xl font-semibold">
                            Cahier de texte numérique
                        </p>

                        <table className="table-auto border mt-2 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full md:text-center"> <a href="pdf/cahier_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r  md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full md:text-center"><a href="pdf/cahier_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank" > Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden border-r font-semibold">Mars: </span>
                                        <span className="md:block w-full md:text-center"><a href="pdf/cahier_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>

                                </tr>
                            </tbody>
                        </table>


                        <p className="mt-12 text-xl font-semibold">
                            Suivi des présences
                        </p>

                        <table className="table-auto border mt-2 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/suivi_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/suivi_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank" > Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/suivi_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>

                                </tr>
                            </tbody>
                        </table>


                        <p className="mt-12 text-xl font-semibold">
                            Frais de scolarité
                        </p>

                        <table className="table-auto border mt-2 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/fraisJanv.pdf" className="rounded-[10px] py-2 px-4 bg-blue-700 w-fit p-2 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/fraisFev.pdf" className="rounded-[10px] py-2 px-4 bg-blue-700 w-fit p-2 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/fraisMars.pdf" className="rounded-[10px] py-2 px-4 bg-blue-700 w-fit p-2 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>
    )
}