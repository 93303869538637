import { PortalNavbar } from "../components/portalNavbar"
import { CourseCard } from "../components/courseCard"
import { useState } from "react"

export function AdminPortal() {
    const [select, setSelect] = useState(null);
    
    return (
        <div className="bg-[rgb(243,244,246)]">
            <PortalNavbar name='RAKOTO Andy' role='Cadre Administratif' ></PortalNavbar>

            <div className="max-w-[1320px] mx-auto pb-12" id="dashboard">

                <div className="flex flex-col sm:flex-row mt-8 cursor-pointer m-auto sm:m-8 sm:mb-2 w-full">
                    <div className="pl-8 md:ml-0 text-[2.2rem] border-b w-full">
                        Annonces
                    </div>


                </div>
                <div className="pl-4 mt-2 md:mt-0">
                    <div className="ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block">
                        Créer une annonce
                    </div>
                </div>

                <div className="mb-4 mb-[-65px] mx-8 ">
                    <div className="text-xl mt-8 border-b">Alertes</div>
                    <div className="flex flex-col">
                        <div className="mt-2">
                            <span className="font-bold">Alerte 01</span> : Cahier de texte numérique Gestion Cours L1 expiré
                        </div>
                        <div className="">
                            <span className="font-bold">Alerte 01</span> : Cahier de texte numérique Professeur Tsiry Rapanoel expire J-2
                        </div>
                        <div className="">
                            <span className="font-bold">Alerte 01</span> : Cahier de texte numérique Comptabilité cours L2 non renseigné
                        </div>
                        <div className="">
                            <span className="font-bold">Alerte 03</span> : Frais scolarité
                        </div>
                    </div>
                    <div className="mt-4 border-b text-xl">Suivi</div>
                    <div className="flex flex-col">
                        <div className="mt-2">
                        <span className="font-bold">Alerte 02</span> : Accréditation Filière gestion expire J-45

                        </div>
                    </div>
                </div>

                <div className="m-8 mt-8 pt-[65px]" id="personnal">
                    <div className="">
                        <h2 className="text-[2.2rem] w-full border-b sm:m-0 sm:mb-2">
                            Cahier de texte numérique
                        </h2>
                    </div>
                    <div className="flex flex-col mt-4">
                        <div className="text-xl border-b w-full">Professeur</div>
                        <div className="flex mt-8 flex-col md:flex-row">
                            <select className="border border-gray-700 px-4 py-1 w-[170px] md:w-[300px] text-ellipsis">
                                <option value=""></option>
                                <option>Tsiry Rapanoel</option>
                                <option>Didier Rakoto</option>
                            </select>
                            <div className="mb-2 mt-2 md:mt-0 md:mb-0 md:ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block w-fit">
                                Ajouter
                            </div>
                            <div className="mb-2 md:mb-0 md:ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block w-fit">
                                Supprimer
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-4">
                        <div className="text-xl border-b w-full">Filière</div>
                        <div className="flex mt-8 flex-col md:flex-row">
                            <select className="border border-gray-700 px-4 py-1 w-[170px] md:w-[300px] text-ellipsis">
                                <option value=""></option>
                                <option>Mention gestion-Comptabilité L2</option>
                                <option>Mention gestion-Ressources Humaines L1</option>
                            </select>
                            <div className="mb-2 mt-2 md:mt-0 md:mb-0 md:ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block w-fit">
                                Ajouter
                            </div>
                            <div className="mb-2 md:mb-0 md:ml-4 bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block w-fit">
                                Supprimer
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-0 mx-8">
                    <h2 className="text-[2.2rem] w-full border-b pt-[20px]">Gestion des habilitations, des accréditations et des partenariats</h2>
                    <div className="w-full">
                        <div className="text-xl border-b w-full mt-4">Habilitations et accréditations</div>
                        <table className="table-auto border mt-4 mx-0 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border w-1/5">ID</th>
                                    <th className="px-4 py-2 border w-1/5">Date d'effet</th>
                                    <th className="px-4 py-2 border w-1/5">Fin de validité</th>
                                    <th className="px-4 py-2 border w-1/5">Type</th>
                                    <th className="px-4 py-2 border w-1/5"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">ID: </span>
                                        <span className="md:table-cell">123</span>
                                    </div>
                                    <div className="p-4 border-b border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Date d'effet: </span>
                                        <span className="md:table-cell">15/10/2018</span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Fin de validité: </span>
                                        <span className="md:table-cell">15/10/2023</span>
                                    </div>
                                    <div className="p-4 md:border-r md:table-cell">
                                        <span className="md:hidden font-semibold">Type: </span>
                                        <span className="md:block text-center"> Mention Gestion</span>
                                    </div>
                                    <div className="p-4 md:table-cell text-center">
                                        <a href="/pdf/Accreditations.pdf" target="_blank" className="text-blue-600 underline">Télécharger</a>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-full mt-4">
                        <div className="text-xl border-b w-full mt-4">Partenariats  </div>
                        <table className="table-auto border mt-4 mx-0 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border w-1/5">ID</th>
                                    <th className="px-4 py-2 border w-1/5">Date d'effet</th>
                                    <th className="px-4 py-2 border w-1/5">Fin de validité</th>
                                    <th className="px-4 py-2 border w-1/5">Type</th>
                                    <th className="px-4 py-2 border w-1/5"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">ID: </span>
                                        <span className="md:table-cell">215</span>
                                    </div>
                                    <div className="p-4 border-b border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Date d'effet: </span>
                                        <span className="md:table-cell">15/10/2022</span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Fin de validité: </span>
                                        <span className="md:table-cell">15/10/2025</span>
                                    </div>
                                    <div className="p-4 md:border-r md:table-cell">
                                        <span className="md:hidden font-semibold">Type: </span>
                                        <span className="md:block text-center">Sparkso</span>
                                    </div>
                                    <div className="p-4 md:table-cell text-center">
                                        <a href="/pdf/Partenariat.pdf" target="_blank" className="text-blue-600 underline">Télécharger</a>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mb-0 mt-4 mx-8">
                    <h2 className="text-[2.2rem] w-full border-b pt-[20px]">Suivi des frais de scolarité</h2>
                    <div className="w-full mt-2">
                        <div className="text-xl border-b w-full mb-4">Filière</div>
                        <select className="border border-gray-700 px-4 py-1 w-full md:w-[300px] text-ellipsis" onChange={(e) => {setSelect(e.target.value);}}>
                                <option value=""></option>
                                <option value="1">Mention Gestion L1</option>
                                <option value="2">Mention Gestion L2</option>
                            </select>
                    </div>
                    {(select && select == 1) &&
                        <>
                            <div className="flex mt-4 overflow-scroll">
                                <div className="flex flex-col pb-4">
                                    <div className="font-bold">
                                        ID Étudiant
                                    </div>
                                    <div className="">
                                        43198
                                    </div>
                                </div>
                                <div className="flex flex-col ml-8">
                                    <div className="font-bold">
                                        Nom
                                    </div>
                                    <div className="">
                                        ANDRIA
                                    </div>
                                </div>
                                <div className="flex flex-col ml-8">
                                    <div className="font-bold">
                                        Prénom
                                    </div>
                                    <div className="">
                                        TSILAVINA MIARANTSOA
                                    </div>
                                </div>
                                <div className="ml-12 flex items-center justify-center">
                                    <div className="bg-black/70 rounded-md text-white p-1 px-4 cursor-pointer inline-block h-auto">
                                        Modifier
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <table className="table-auto border mt-4 mx-0 min-w-full">
                                    <thead className="hidden md:table-header-group">
                                        <tr>
                                            <th className="px-4 py-2 border"></th>
                                            <th className="px-4 py-2 border">Statut</th>
                                            <th className="px-4 py-2 border">Commentaire</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="md:table-row">
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold"></span>
                                                <span className="md:table-cell">Janvier 2023</span>
                                            </div>
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Statut</span>
                                                <span className="md:table-cell">Payé</span>
                                            </div>
                                            <div className="p-4 border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Commentaires</span>
                                                <span className="md:table-cell"></span>
                                            </div>
                                        </tr>
                                        <tr className="md:table-row">
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold"></span>
                                                <span className="md:table-cell">Février 2023</span>
                                            </div>
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Statut</span>
                                                <span className="md:table-cell">Payé</span>
                                            </div>
                                            <div className="p-4 border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Commentaires</span>
                                                <span className="md:table-cell"></span>
                                            </div>
                                        </tr>
                                        <tr className="md:table-row">
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold"></span>
                                                <span className="md:table-cell">Mars 2023</span>
                                            </div>
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Statut</span>
                                                <span className="md:table-cell">Payé</span>
                                            </div>
                                            <div className="p-4 border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Commentaires</span>
                                                <span className="md:table-cell"></span>
                                            </div>
                                        </tr>
                                        <tr className="md:table-row">
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold"></span>
                                                <span className="md:table-cell">Mars 2023</span>
                                            </div>
                                            <div className="p-4 border-b-0 md:border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Statut</span>
                                                <span className="md:table-cell">Report</span>
                                            </div>
                                            <div className="p-4 border-b md:border-r md:table-cell">
                                                <span className="md:hidden font-semibold">Commentaires</span>
                                                <span className="md:table-cell">Validé</span>
                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                    
                </div>

                <div className="m-8 mt-8" id="personnal">
                    <div className="">
                        <h2 className="text-[2.2rem] w-full border-b sm:m-0 sm:mb-2">
                            Espace personnel
                        </h2>
                        <a href="/employee/38990" className="mb-8 bg-blue-600 rounded-md text-white p-1 px-4 cursor-pointer inline-block">Consulter les données personnelles</a>
                    </div>



                    <div className="w-full mb-4">
                        <p className="mt-4 text-xl font-semibold">
                            Cahier de texte numérique
                        </p>

                        <table className="table-auto border mt-2 w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/cahier_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r  md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/cahier_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank" > Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden border-r font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/cahier_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>

                                </tr>
                            </tbody>
                        </table>


                        <p className="mt-12 text-xl font-semibold">
                            Suivi des présences
                        </p>

                        <table className="table-auto border mt-2 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/suivi_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/suivi_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank" > Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/suivi_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>

                                </tr>
                            </tbody>
                        </table>

                        <p className="mt-12 text-xl font-semibold">
                            Fiche de paie
                        </p>

                        <table className="table-auto border mt-2 min-w-full">
                            <thead className="hidden md:table-header-group">
                                <tr>
                                    <th className="px-4 py-2 border">Janvier</th>
                                    <th className="px-4 py-2 border">Février</th>
                                    <th className="px-4 py-2 border">Mars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="md:table-row">
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Janvier: </span>
                                        <span className="md:block w-full text-center"> <a href="pdf/paie_janvier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a> </span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Février: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/paie_fevrier.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>
                                    <div className="p-4 border-b md:border-r md:border-b-0 md:table-cell">
                                        <span className="md:hidden font-semibold">Mars: </span>
                                        <span className="md:block w-full text-center"><a href="pdf/paie_mars.pdf" className="rounded-[10px] bg-blue-700 w-fit py-2 px-4 m-auto text-white cursor-pointer mt-4 mb-4" target="_blank"> Télécharger</a></span>
                                    </div>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    )
}