import QrReader from "modern-react-qr-reader";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { getEmployeeData, getStudentData } from "../function";
import { ToastContainer, toast } from 'react-toastify';
import QrScanner from 'qr-scanner';

export default function Scan({}){
    const navigate = useNavigate();
    const [scanning, setScanning] = useState();
    const [teacher, setTeacher] = useState(false);
    const [connectModal, setConnectModal] = useState(false);
    const [connectedAs, setConnectedAs] = useState(null);
    const [connecting, setConnecting] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPasswordOne, setShowNewPasswordOne] = useState(false);
    const [showNewPasswordTwo, setShowNewPasswordTwo] = useState(false);
    const [firstLoginModal, setFirstLoginModal] = useState(false);
    const idRef = useRef(null);
    const usernameRef = useRef(null);
    const passRef = useRef(null);
    const ids = [38990, 43198, 123, 234, 345, 456];
    const videoElem = useRef(null);
    const oldPassRef = useRef(null);
    const newPassOneRef = useRef(null);
    const newPassTwoRef = useRef(null);
    var qrScanner = null;
    
    useEffect(() => {
        if(scanning == true){
            qrScanner = new QrScanner(
                videoElem.current,
                result => handleScan(result.data),
                { returnDetailedScanResult: true, preferredCamera: 'user' },
            );
            qrScanner.start();
        }
    }, [scanning])

    useEffect(() => {
        setConnectedAs(localStorage.getItem("accountType"));
    }, []);

    async function handleScan(data){
        qrScanner.stop();
        console.log(data);
        if(data){
            const id = data.split("/").reverse()[0];
            await fetch("https://sparkso-universite.com:8081/api/scan", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id
                })
            }).then((response) => 
                response.json()
            ).then((datas) => {
                if(datas.length){
                    navigate(`/mahajanga/IUGM/${id}`);
                } else {
                    // TODO: Delete later
                    if(ids.includes(parseInt(id))){
                        if(teacher){
                            if(id == 38990){
                                navigate(`/editEmployee/${id}`);
                            } else if (id == 123 || id == 234 || id == 345) {
                                navigate(`/diplome/${id}`);
                            } else {
                                navigate(`/teacher/${id}`);
                            }
                        } else if (id) {
                            if (id == 38990) {
                                navigate(`/employee/${id}`)
                            } else if (id == 123 || id == 234 || id == 345) {
                                navigate(`/diplome/${id}`);
                            } else {
                                navigate(`/${id}`);
                           }
                        }
                    } else {
                        console.log(2);
                    }
                }
            });
        }
    }

    function handleError(err){
        console.log(err);
    }

    async function handleSubmit(e){
        e.preventDefault();
        const id = idRef.current.value;
        const result = await fetch("https://sparkso-universite.com:8081/api/scan", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
                scanner: connectedAs ? connectedAs : null
            })
        }).then((response) => 
            response.json()
        ).then((datas) => {
            if (datas.length) {
                navigate(`/mahajanga/IUGM/${id}`);
            } else {
                // TODO: DELETE LATER
                if(idRef.current.value && ids.includes(parseInt(idRef.current.value))){
                    if(teacher){
                        if(idRef.current.value == 38990){
                            navigate(`/editEmployee/${idRef.current.value}`);
                        } else if (idRef.current.value == 123 || idRef.current.value == 234 || idRef.current.value == 345) {
                            navigate(`/diplome/${idRef.current.value}`);
                        } else {
                            navigate(`/teacher/${idRef.current.value}`);
                        }
                    } else {
                        if (idRef.current.value == 38990) {
                            navigate(`/employee/${idRef.current.value}`);
                        } else if (idRef.current.value == 123 || idRef.current.value == 234 || idRef.current.value == 345) {
                            navigate(`/diplome/${idRef.current.value}`);
                    }else {
                            navigate(`/${idRef.current.value}`);
                        }
                    }
                } else {
                    toast.error("ID Erroné");
                }
            }

        })

        
    }

    function displayConnectModal(){
        if(localStorage.getItem("accountType")){
            localStorage.removeItem("accountType");
            localStorage.removeItem("student");
            setConnectedAs(null);
        } else {
            setConnectModal(!connectModal);
        }
    }

    function toggleFirstLoginModal(){

        setFirstLoginModal(!firstLoginModal);
    }

    function connect(){
        const username = usernameRef.current.value;
        const password = passRef.current.value;

        setConnecting(true);
        fetch('https://sparkso-universite.com:8081/api/login', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: username,
                password: password
            })
        }).then((response) => 
            response.json()
        ).then((datas) => {
            setConnecting(false);
            if(datas.result == "error"){
                toast.error("Compte inexistant");
                console.log("error");
            } else {
                displayConnectModal();
                console.log(datas);
                if(datas.firstLogin == false && datas.accountType == "student"){
                    toggleFirstLoginModal();
                }
                localStorage.setItem("accountType", datas.accountType);
                if(datas.accountType == "student") {
                    localStorage.setItem("student", username);
                }
                setConnectedAs(datas.accountType);
            }
        })
    }

    async function changePassword(e){
        e.preventDefault();
        const oldPass = oldPassRef.current.value;
        const newPassOne = newPassOneRef.current.value;
        const newPassTwo = newPassTwoRef.current.value;
        
        if(newPassOne != newPassTwo){
            toast.error("Les mots de passes entrés ne correspondent pas.");
        }

        if(newPassOne.length < 7){
            toast.error("Votre nouveau mot de passe doit faire au moins 7 caractères.");
        }

        fetch("https://sparkso-universite.com:8081/api/changePassword", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: localStorage.getItem("student"),
                oldPass: oldPass,
                newPass: newPassOne
            })
        }).then((response) => 
            response.json()
        ).then((datas) => {
            if (datas.result == "wrong password") {
                toast.error("Ancien mot de passe érroné.")
            } else if (datas.result == "not found") {
                toast.error("Une erreur est survenue. Veuillez vous déconnecter et réessayer.");
            } else {
                toast.success("Mot de passe modifié avec succès.");
                setFirstLoginModal(false);
            }
        });

    }

    return (
        <div className="bg-cover h-screen flex flex-col bg-[url('/public/bg.jpeg')]">
            {/* {localStorage.getItem("accountType") && 
                <>Connecté en tant que: {localStorage.getItem("accountType")}</>
            } */}
            {firstLoginModal && 
                <div className="absolute w-full h-full bg-black/40 z-[100]">
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="shadow w-[95%] md:w-1/2 h-[600px] mx-auto bg-white backdrop-blur-sm rounded-md relative flex flex-col p-8 relative">
                        <div className="absolute top-8 right-5">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="h-6 w-6 rotate-45 cursor-pointer" onClick={() => setFirstLoginModal(false)}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </div>
                            <div className="text-blue-500 text-left text-xl mb-4">Modifier mon mot de passe</div>
                            <div>Pour votre sécurité, veuillez choisir un nouveau mot de passe à l'aide des champs ci-dessous.</div>
                            <div className="mb-8 underline">Celui-ci doit faire au moins 7 caractères.</div>
                            <div className="py-2">
                                <label for="oldpassword" className="px-1 text-sm text-gray-600 cursor-pointer">Ancien mot de passe</label>
                                <div className="relative">
                                    <input ref={oldPassRef} id="oldpassword" name="oldpassword" placeholder="" type={showOldPassword ? 'text' : 'password'} class="
                                        text-md block px-3 py-2 rounded-lg w-1/2 
                                        bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md
                                        focus:placeholder-gray-500
                                        focus:bg-white
                                        focus:border-gray-600
                                        focus:outline-none
                                        mb-8" />
                                    <div className="absolute inset-y-0 right-1/2 pr-3 flex items-center text-sm leading-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowOldPassword(true)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`cursor-pointer h-6 w-6 ${showOldPassword ? "hidden" : "block"}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowOldPassword(false)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`cursor-pointer h-6 w-6 ${showOldPassword ? "block" : "hidden"}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    </div>
                                </div>

                                <label for="newpasswordone" className="px-1 text-sm text-gray-600 cursor-pointer">Nouveau mot de passe</label>
                                <div className="relative">
                                    <input ref={newPassOneRef} id="newpasswordone" name="newpasswordone" placeholder="" type={showNewPasswordOne ? 'text' : 'password'} class="
                                        text-md block px-3 py-2 rounded-lg w-1/2 
                                        bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md
                                        focus:placeholder-gray-500
                                        focus:bg-white
                                        focus:border-gray-600
                                        focus:outline-none mb-8" />
                                    <div className="absolute inset-y-0 right-1/2 pr-3 flex items-center text-sm leading-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowNewPasswordOne(true)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`cursor-pointer h-6 w-6 ${showNewPasswordOne ? "hidden" : "block"}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowNewPasswordOne(false)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`cursor-pointer h-6 w-6 ${showNewPasswordOne ? "block" : "hidden"}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    </div>
                                </div>

                                <label for="newpasswordtwo" className="px-1 text-sm text-gray-600 cursor-pointer">Confirmer nouveau mot de passe</label>
                                <div className="relative">
                                    <input ref={newPassTwoRef} id="newpasswordtwo" name="newpasswordtwo" placeholder="" type={showNewPasswordTwo ? 'text' : 'password'} class="
                                        text-md block px-3 py-2 rounded-lg w-1/2 
                                        bg-white border-2 border-gray-300 placeholder-gray-600 shadow-md
                                        focus:placeholder-gray-500
                                        focus:bg-white
                                        focus:border-gray-600
                                        focus:outline-none" />
                                    <div className="absolute inset-y-0 right-1/2 pr-3 flex items-center text-sm leading-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowNewPasswordTwo(true)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`cursor-pointer h-6 w-6 ${showNewPasswordTwo ? "hidden" : "block"}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>

                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowNewPasswordTwo(false)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`cursor-pointer h-6 w-6 ${showNewPasswordTwo ? "block" : "hidden"}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    </div>
                                </div>

                                <div type="submit" value="Change password" onClick={changePassword} className="cursor-pointer bg-blue-600 text-white px-2 py-1 rounded-md mt-8 inline hover:scale-95 transition-all duration-20">Changer de mot de passe</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="flex-1 flex flex-col-reverse md:flex-row items-center justify-center max-w-[1320px] mx-auto w-full">
                <div className="shadow w-[95%] md:w-1/2 h-[600px] mx-auto bg-white backdrop-blur-sm rounded-md relative flex flex-col items-center">
                    <div className="absolute top-2 right-4">
                        {!connectModal &&
                        <>
                            { !connectedAs &&
                                <div className="inline-block bg-blue-500 float-right text-white px-2 py-1 rounded-md cursor-pointer hover:scale-95" onClick={() => displayConnectModal()}>Connexion</div>
                            }
                            { connectedAs &&
                                <div className="inline-block bg-blue-500 float-right text-white px-2 py-1 rounded-md cursor-pointer hover:scale-95" onClick={() => displayConnectModal()}>Déconnexion</div>
                            }
                        </> 
                        }
                    </div>
                    <div className="h-1/4 w-full py-2 shadow shadow-b overflow-hidden flex flex-col items-center mb-4">
                        <div className="text-xl text-center">Université Mahajanga</div>
                        <a href="/">
                            <img src="/mahajanga.png" className="mx-auto w-[80px] mt-4"/>
                        </a>
                    </div>
                    <div className="text-center flex items-center pt-8 flex-col flex-1">
                        <button onClick={() => {setScanning(true)}} className="shadow bg-ostie px-3 py-2 rounded-md border rounded-md"><span className="underline font-bold">
                            Scanner un QR CODE</span>
                        </button>
                        <div className="my-4">ou</div> 
                        <div>
                            Entrer votre identifiant (Etudiant, Professeur ou Employé)
                        </div>
                        <form onSubmit={handleSubmit}>
                            <input ref={idRef} type="text" className="border-y border-l border-black rounded-l-md px-3 py-2 text-center"/>
                            <input type="submit" value="Valider" className="cursor-pointer shadow shadow-md border-r border-y border-black rounded-r-md px-3 py-2 hover:bg-gray-400"/>
                        </form>
                        {/* <div className="mt-8 max-w-[1300px] mx-auto text-center mb-2">
                            <input id="teacher" type="checkbox" onClick={() => toggleTeacher()}/>
                            <label for="teacher" className="ml-2">Je suis administrateur</label>
                        </div> */}
                        { (connectedAs && connectedAs) == "admin" &&
                            <div className="mt-8 max-w-[1300px] mx-auto text-center mb-2">
                                <div className="ml-2 mb-2 underline cursor-pointer text-blue-500" onClick={() => { navigate("/newStudent"); } }>Créer un étudiant</div>
                                <div className="ml-2 underline cursor-pointer text-blue-500" onClick={() => { navigate("/import"); } }>Importer une base de données</div>
                            </div>
                        }
                        {(connectedAs && connectedAs == "student") && 
                            <div className="w-full mt-8 text-left">
                                <div className="w-full h-[1px] bg-gray-400"></div>
                                <div className="pt-4">Connecté en tant que <span className="font-bold text-blue-500">{localStorage.getItem("student")}</span></div>
                                <div className="text-blue-500 underline cursor-pointer" onClick={() => setFirstLoginModal(true)}>Modifier mon mot de passe</div>
                            </div>
                        }
                    </div>
                </div>
                {scanning &&
                    // <div className="relative bg-gray-100">
                    //     <QrReader
                    //         delay={500}
                    //         onScan={handleScan}
                    //         style={{ maxWidth: "400px", margin: "0 auto", transform: "rotateY(180deg)", width: "400px"}}
                    //         facingMode={"user"}
                    //         constraints={ {facingMode: 'environment'} }
                    //         onError={handleError}
                    //     />
                    // </div>
                    <video className="relative" style={{zIndex: 98}} width={400} ref={videoElem}></video>
                }
                {connectModal && 
                    <div className="absolute max-w-[1320px] mx-auto h-full z-50 flex-1 flex flex-col-reverse md:flex-row items-center justify-center mx-auto w-full">
                        <div className="shadow w-[95%] md:w-1/2 h-[600px] mx-auto bg-white rounded-md relative flex flex-col items-center">
                            <div className="absolute absolute top-2 right-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 rotate-45 cursor-pointer hover:scale-90" onClick={() => displayConnectModal()}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                            </div>
                            <div className="h-1/4 w-full py-2 shadow shadow-b overflow-hidden flex flex-col items-center mb-4">
                                <div className="text-xl text-center">Université Mahajanga</div>
                                <a href="/">
                                    <img src="/mahajanga.png" className="mx-auto w-[80px] mt-4"/>
                                </a>
                            </div>
                            <div className="text-center flex items-center pt-8 flex-col flex-1">
                                <form onSubmit={(e) => { e.preventDefault(); connect(); } }>
                                    <div className="flex items-center mb-8">
                                        <label htmlFor="username" className="mr-4 w-[200px]">Nom d'utilisateur</label>
                                        <input ref={usernameRef} id="username" type="text" className="border border-black rounded-md px-5 py-1 text-center"/>
                                    </div>
                                    <div className="flex items-center mb-8">
                                        <label htmlFor="password" className="mr-4 w-[200px]">Mot de passe</label>
                                        <input ref={passRef} id="password" type="password" className="border border-black rounded-md px-5 py-1 text-center"/>
                                    </div>
                                    <div className="flex float-right mt-8 relative">
                                        <input disabled={connecting} type="submit" className={`disabled:opacity-40 inline-block bg-blue-600 text-white px-4 py-1 rounded-md cursor-pointer hover:scale-95 ${connecting ? "pr-8" : ""}`} value="Connexion"></input>
                                        {connecting && 
                                            <div role="status" className="absolute top-1/2 right-2 -translate-y-1/2">
                                                <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                </svg>
                                            </div>                                       
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
