export function InformationBlocks(props) {

    function renderLessonsKey(key){
        switch(key){
            case 'Présent':
                return 'Présent(e)';
                break;
            case 'Absent':
                return 'Absent(e)';
                break;
            case 'Excusé':
                return 'Excusé(e)';
                break;
            default:
                return 'Nombre de cours';
                break;
        }
    }

    return (
        <>
            <div className={` mt-0 ${(props.employeeDegreeData || props.jobsData ? "w-[90%] lg:w-[95%] self-end" : "w-[90%] lg:w-[95%]")} ${props.jobsData? "!w-[90%] md:!w-full" : ""} border break-words rounded-[10px] overflow-hidden mx-auto md:mx-0`} >
                <div className="bg-[#bdd7ee] border-b border-[rgba(0,0,0,.175)]">
                    <p className="p-4 text-[1.5rem] uppercase">
                        {props.title}
                    </p>
                </div>

                {props.personnalData &&
                    <>
                        <div className="flex flex-col justify-between gap-2 px-4 md:text-left">
                            <div className="w-full flex border-b border-[#CECECE] p-4">
                                <p className="min-w-[40%] font-extrabold">
                                    ID
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.id}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Nom
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.personnalData["Prenom"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Prénom
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.personnalData["Nom"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Date de naissance

                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.personnalData["Date de naissance"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Lieu de naissance
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.personnalData["Lieu de naissance"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 pb-4 px-4 border-b border-[#CECECE]">
                                <p className="min-w-[40%] font-extrabold">
                                    Sexe
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.personnalData["Sexe"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-4 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    CNI / Passeport
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.personnalData["CNI/Passeport"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Délivré(e) le
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.personnalData["Delivery Date"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 pb-4 px-4 border-b border-[#CECECE]">
                                <p className="min-w-[40%] font-extrabold">
                                    Lieu
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-4 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Père
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    Mr. {props.personnalData["Père"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-4 pb-4 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Mère
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    Mme. {props.personnalData["Mère"]}
                                </p>
                            </div>
                        </div>
                    </>
                }

                {props.cursusData &&
                    <>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex p-4 pb-0">
                                <p className="min-w-[40%] font-extrabold">
                                    Année universitaire
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.cursusData["Année universitaire"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Niveau
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.cursusData["Niveau"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Parcours
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.cursusData["Parcours"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Statut
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    {props.cursusData["Statut"]}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between gap-2 px-4">
                            <div className="w-full flex pt-2 px-4 pb-4">
                                <p className="min-w-[40%] font-extrabold">
                                    Situation
                                </p>
                                <p className="flex-1 text-right md:text-left">
                                    Rattrapage
                                </p>
                            </div>
                        </div>
                    </>
                }

                {props.contactData && Object.keys(props.contactData).map((key, index) => (
                    <>
                        <div className="flex justify-between gap-2  p-4 break-words">
                            <p className=" font-extrabold">
                                {key}
                            </p>
                            <div className="break-words">
                                {props.contactData[key]}
                            </div>

                        </div>
                    </>
                ))}

                
                {props.lessonsData && 
                    <div className="flex justify-between overflow-x-scroll">
                    {Object.keys(props.lessonsData).map((key, index) => (
                        <div className="flex flex-col justify-between gap-2  p-4 break-words">
                            <p className=" font-extrabold">
                                {renderLessonsKey(key)}
                            </p>
                            <div className="break-words text-center">
                                {props.lessonsData[key]}
                            </div>

                        </div>
                    ))}
                    </div>
                }

                {(props.degreeData) && (
                    <>
                        <div className="overflow-x-auto gap-4 pl-4">
                            <tr className="pl-6">
                                {Object.keys(props.degreeData).map(key => (
                                    <th className="p-2" key={key}>{key == "Diplome" ? "Diplôme" : key}</th>
                                ))}
                                <th className="p-2">Voir</th>
                            </tr>
                            <tr className="">
                                {Object.values(props.degreeData).map(value => (
                                    <td className="p-2" key={value}>{value}</td>
                                ))
                                }
                                <a href={`/diplome/${props.degreeData.Numéro}`} className="p-2 flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                    </svg>
                                </a>
                            </tr>
                        </div>
                    </>
                )}


                {props.jobsData && (
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2">Année</th>
                                    <th className="px-4 py-2">Poste</th>
                                    <th className="px-4 py-2">Société</th>
                                    <th className="px-4 py-2">Division</th>
                                    <th className="px-4 py-2">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.jobsData.map((item, index) => (
                                    <tr key={index}>
                                        <td className="border px-4 py-2">{item.emjJobYear}</td>
                                        <td className="border px-4 py-2">{item.emjJobTitle}</td>
                                        <td className="border px-4 py-2">{item.emjJobCompany}</td>
                                        <td className="border px-4 py-2">{item.emjJobDIvision}</td>
                                        <td className="border px-4 py-2 w-80">{item.emjJobDescription}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}


                {/* make this responsive */}
                {props.employeeDegreeData && (
                    <>
                        <div className="overflow-x-auto">
                            <table className="table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2">Diplôme</th>
                                        <th className="px-4 py-2">Série</th>
                                        <th className="px-4 py-2">Numéro</th>
                                        <th className="px-4 py-2">Date</th>
                                        <th className="px-4 py-2"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border px-4 py-2">BACCALAUREAT</td>
                                        <td className="border px-4 py-2">A2</td>
                                        <td className="border px-4 py-2">4240206</td>
                                        <td className="border px-4 py-2">2000</td>
                                        <td className="px-4 py-2"></td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2">DEUG</td>
                                        <td className="border px-4 py-2">Sciences de Gestion</td>
                                        <td className="border px-4 py-2">4240207</td>
                                        <td className="border px-4 py-2">2002</td>
                                        <td className="px-4 py-2"></td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2">LICENCE</td>
                                        <td className="border px-4 py-2">Sciences de Gestion</td>
                                        <td className="border px-4 py-2">234</td>
                                        <td className="border px-4 py-2">2003</td>
                                        <td className="px-4 py-2 text-blue-500 underline">
                                            <a href="/diplome/234">Voir
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline align-middle ml-1 mb-0.5 w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2">MAITRISE</td>
                                        <td className="border px-4 py-2">Sciences de Gestion</td>
                                        <td className="border px-4 py-2">345</td>
                                        <td className="border px-4 py-2">2005</td>
                                        <td className="px-4 py-2 text-blue-500 underline"><a href="/diplome/345">Voir 
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline align-middle ml-1 mb-0.5 w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                            </svg>
                                        </a></td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2">DEA</td>
                                        <td className="border px-4 py-2">Économie</td>
                                        <td className="border px-4 py-2">8001</td>
                                        <td className="border px-4 py-2">2008</td>
                                        <td className="px-4 py-2"></td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2">DOCTORAT</td>
                                        <td className="border px-4 py-2">Économie</td>
                                        <td className="border px-4 py-2">8002</td>
                                        <td className="border px-4 py-2">2012</td>
                                        <td className="px-4 py-2"></td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2">HDR</td>
                                        <td className="border px-4 py-2">Sciences de Gestion</td>
                                        <td className="border px-4 py-2">8003</td>
                                        <td className="border px-4 py-2">2021</td>
                                        <td className="px-4 py-2"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}



                {props.scolarityFeesData && Object.keys(props.scolarityFeesData).map((key, index) => (
                    <>
                        <div className="flex justify-between gap-2  p-4">
                            <p className="font-extrabold">
                                {key}
                            </p>

                            {props.scolarityFeesData[key]}
                        </div>
                    </>
                ))}
            </div>
        </>
    )
}