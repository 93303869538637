import { addCursus, getCursus, getStudentData, uploadImage, removeCursus } from "../function"
import { useState, useEffect } from "react";
import { EditableInformationBlocks } from "../components/editableInformationBlocks";
import { updateStudentData } from "../function";
import { useParams, Link } from 'react-router-dom';
import { toast } from "react-toastify";

export default function EditStudent() {

    //should install react toastify and toast on success and error 
    const [personnalData, setPersonnalData] = useState();
    const [cursusData, setCursusdata] = useState();
    const [degreeData, setDegreeData] = useState();
    const [contactData, setContactData] = useState();
    const [image, setImage] = useState(null);
    const [addedDatas, setAddedDatas] = useState([]);

    const handleImageUpload = async (event) => {

        setImage(event.target.files[0]);
    };

    let { id } = useParams();

    useEffect(() => {

        getStudentData(id).then((data) => {
            setPersonnalData({ 'Nom': data.stLastname, 'Prenom': data.stfirstname, 'Date de naissance': data.stBirthdate, "Lieu de naissance": data.stBirthPlace, "Sexe": data.stSex, "CNI/Passeport": data.stPassport, "Date de délivrance": data.stPassportDeliveryDate, "Père": data.stFather, "Mère": data.stMother })
            // setCursusdata({ "Année universitaire": data.stUniversityYear, "Niveau": data.stCursus, "Parcours": "", "Statut": data.stSituation });
            setContactData({ "Téléphone": data.stPhone, "Email": data.stMail, "Adresse": data.stAddress });
            setDegreeData({ "Diplôme": data.stDegree, "Série": data.stDegreeSerie, "Numéro": data.stDegreeNumber, "Date": data.stDegreeDate })
        });

        getCursus(id).then((response) => {
            setCursusdata(response);
        });
    }, [])

    function addCursusLine(){
        setCursusdata([
            ...cursusData,
            {
                "cursus_name": "",
                "cursus_year": "",
                "cursus_level": "",
                "cursus_status": "",
                "cursus_session": "",
                "cursus_student": id
            }
        ])
    }

    function removeCursusLine(index){
        console.log(cursusData[index].cursus_id);
        removeCursus(cursusData[index].cursus_id);
        const cursusDataUpdated = cursusData.splice(index, 1);
        console.log(cursusData);
        setCursusdata([...cursusData]);
    }

    function editCursus(field, index, value){
        const currentCursusData = cursusData[index];
        currentCursusData[field] = value;
        cursusData[index] = currentCursusData;
        setCursusdata(cursusData);
    }

    function updateData() {
        console.log('enter in update')
        let lastName = document.getElementById('Nom').value
        let firstName = document.getElementById('Prenom').value
        let birthDate = document.getElementById('Date de naissance').value
        let birthplace = document.getElementById('Lieu de naissance').value
        let Sex = document.getElementById('Sexe').value
        let passeport = document.getElementById('CNI/Passeport').value
        let stPassportDeliveryDate = document.getElementById('Date de délivrance').value;
        let father = document.getElementById('Père').value;
        let mother = document.getElementById('Mère').value;
        let phoneNumber = document.getElementById('Téléphone').value;
        let email = document.getElementById('Email').value;
        let address = document.getElementById('Adresse').value;
        // let cursus = document.getElementById('Niveau').value;
        // let situation = document.getElementById('Statut').value;
        // let universityYear = document.getElementById('Année universitaire').value;
        let degree = document.getElementById('Diplôme').value;
        let degreeSerie = document.getElementById('Série').value;
        let degreeNumber = document.getElementById('Numéro').value;
        let degreeDate = document.getElementById('Date').value;

        const imagesFormData = new FormData();
        imagesFormData.append("uId", id);
        imagesFormData.append("image", image);

        const cursusToSend = cursusData.filter((e) => {
            return !Object.values(e).every((e) => e == null || e == "" );
        });

        addCursus(cursusToSend);

        console.log(imagesFormData);
        uploadImage(imagesFormData);

        const studentPic = image;
        console.log(studentPic)

        updateStudentData(id, firstName, lastName, birthDate, birthplace, Sex, passeport, stPassportDeliveryDate, father, mother, phoneNumber, email, address, degree, degreeSerie, degreeNumber, degreeDate)

        toast.info("Mise à jour effectuée !");
        setTimeout(() => {
            window.location.reload();
        }, 2000)
    }

    return (
        <>
            <div className="bg-[#44546a] w-full h-[150px] flex items-center relative">
                <div className="max-w-[1300px] mx-auto w-full flex items-center h-full">
                    <img src={`https://sparkso-universite.com:8081/api/uploads/${id}.png`} alt="" width="100" className="" />
                    <div className="flex-1">
                        <p className="text-white text-center text-3xl uppercase flex-1">
                            Université SPARKSO
                        </p>
                        <p className="text-gray-400 text-center text-xl">{personnalData && <>{personnalData["Prenom"]} <span className="capitalize">{personnalData["Nom"].toLowerCase()}</span></>}</p>
                    </div>
                </div>
                <Link to="/scan" className="absolute left-5 text-white bottom-7 underline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-4 h-4 mb-0.5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
Retour</Link>
            </div>

            <div className="w-fit m-auto block mt-6 mb-6">
                <p> Changer la photo de l'étudiant(e)</p>
                <input type="file" name='profilePic' onChange={handleImageUpload} />
            </div>

            <div className="flex gap-6 lg:w-[70%]  m-auto flex-col sm:flex-row">


                {/* <hr className=" h-[2px] bg-black w-[95%] text-center m-auto" /> */}

                {personnalData &&
                    <EditableInformationBlocks title='ETUDIANT(E)' personnalData={personnalData} />}

                {cursusData &&
                    <>
                        <div className={`mt-2 flex-col sm:flex-row w-[90%] lg:w-[70%] align-center justify-center items-center  border  m-auto mb-4 break-words `} >
                            <div className="bg-[#bdd7ee] uppercase">
                                <p className="p-4 font-bold">
                                    Cursus
                                </p>
                            </div>
                            <div className="overflow-scroll flex-1 gap-4">
                                <tr>
                                    <th className="w-auto  border md:w-1/5 p-2">Année universitaire</th>
                                    <th className="w-auto  border md:w-1/6 p-2">Niveau</th>
                                    <th className="w-auto  border md:w-1/5 p-2">Parcours</th>
                                    <th className="w-auto  border md:w-1/5 p-2">Statut</th>
                                    <th className="w-auto  border md:w-1/5 p-2">Session</th>
                                    <th className="w-auto  border md:w-1/5 p-2">Action</th>
                                    </tr>
                                {cursusData.map((cursus, key) => 
                                    <tr key={key} className={key}>
                                        <td className="p-2 border" onChange={(e) => editCursus("cursus_year", key, e.target.value)}><input type="text" className="border-b" defaultValue={cursus.cursus_year || ""} /></td>
                                        <td className="p-2 border" onChange={(e) => editCursus("cursus_level", key, e.target.value)}><input type="text" className="border-b" defaultValue={cursus.cursus_level || ""} /></td>
                                        <td className="p-2 border" onChange={(e) => editCursus("cursus_name", key, e.target.value)}><input type="text" className="border-b" defaultValue={cursus.cursus_name || ""} /></td>
                                        <td className="p-2 border" onChange={(e) => editCursus("cursus_status", key, e.target.value)}><input type="text" className="border-b" defaultValue={cursus.cursus_status || ""} /></td>
                                        <td className="p-2 border" onChange={(e) => editCursus("cursus_session", key, e.target.value)}><input type="text" className="border-b" defaultValue={cursus.cursus_session || ""} /></td>
                                        {key > 0 &&
                                            <td className="p-2 relative" onClick={(e) => {console.log(key); removeCursusLine(key)}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-[50%] left-[50%] [transform:translate(-50%,-50%)] w-6 h-6 text-red-600 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg></td>
                                        }
                                    </tr>
                                )}
                                <div className="w-full text-center">
                                    <button className="bg-green-300 shadow shadow-md font-bold px-8 py-2 mt-2 rounded-[10px]" onClick={addCursusLine}>Ajouter un cursus</button>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* {cursusData &&
                    <EditableInformationBlocks title='CURSUS' cursusData={cursusData} />
                } */}

            </div>

            <div className="flex gap-6 lg:w-[70%]  m-auto flex-col sm:flex-row">

                {contactData &&
                    <EditableInformationBlocks title='CONTACT' contactData={contactData} />
                }

                {degreeData &&
                    <EditableInformationBlocks title='DIPLOMES / CERTIFICATION' degreeData={degreeData} />

                }
            </div>

            <button className="bg-green-300 shadow shadow-md font-bold px-8 py-2 mt-2 mb-12 rounded-[10px] m-auto block" onClick={updateData}>Sauvegarder</button>
        </>
    )
}