import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Login() {
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(false);

    function login(e) {
        setFetching(true);
        e.preventDefault();
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;

        fetch(`https://sparkso-universite.com:8081/api/logUser`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: username,
                password: password
            })
        }).then((response) =>
            response.json()
        ).then(data => {
            setFetching(false);
            if (data.result !== "error") {
                const accountType = data.accountType
                console.log(accountType);
                switch (accountType){
                    case 'admin':
                        navigate('/adminPortal');
                        break;
                    case 'teacher':
                        navigate('/professorPortal');
                        break;
                    case 'student':
                        navigate('/studentPortal');
                        break;
                }

            } else {
                toast.error("Identifiant/Mot de passe incorrect");
                setTimeout(() => {
                    setError(false);
                }, 5000);
            }
        }).catch((err) => {
            setFetching(false);
            console.log(err);
        });
    }

    return (
        <>
            <div className="flex items-center h-screen bg-gray-100 relaitve">
                <a href="/" className="text-blue-600 absolute top-5 left-5 text-xl underline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-5 h-5 mb-0.5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>Accueil</a>
                <div className="shadow w-[95%] lg:w-1/3 h-[600px] mx-auto bg-white rounded-md relative">
                    <div className="flex h-full w-full overflow-hidden flex-col">
                        <div className="h-1/4 w-full py-2 shadow shadow-b overflow-hidden flex items-center mb-4">
                            <img src="/sparkso.png" className="mx-auto w-[80px]"/>
                        </div>
                        <div className="flex-1 w-full mt-4">
                            <form onSubmit={(e) => login(e)}>
                                <div className="text-lg text-center mb-4">Connexion « Compte SPARKSO »</div>
                                <div class="relative z-0 w-2/3 mx-auto mb-8">
                                    <input ref={usernameRef} type="text" id="username" class="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-200 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label for="username" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nom d'utilisateur</label>
                                </div>
                                <div class="relative z-0 w-2/3 mx-auto">
                                    <input ref={passwordRef} type="password" id="password" class="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-200 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label for="password" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Mot de passe</label>
                                </div>
                                {error &&
                                    <div className="text-red-600 absolute left-1/2 -translate-x-1/2 pt-2">Mauvais pseudo ou mot de passe</div>
                                }
                                <div className="w-2/3 mx-auto">
                                    <input type="submit" className="bg-blue-600 text-white hover:bg-blue-700 h-[42px] w-full block rounded-md mt-8 uppercase" value={fetching ? "CONNEXION ..." : "SE CONNECTER"}/>
                                </div>
                            </form>
                        </div>
                        <div className="w-2/3 mx-auto pb-6">
                            <p className="mb-4">Ne perdez pas accès à votre compte: <a href="#" className="block text-blue-600 underline">Configurez la récupération de mot de passe.</a></p>
                            <p className="mb-4">Protégez votre compte et <a href="#" className="text-blue-600 underline">activez l'authentification a deux facteurs</a> en suivant les <a href="#" className="text-blue-600 underline">instructions</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
