import { useDraggable } from "@dnd-kit/core";

export function SidebarField(props) {
  const { title, key } = props;

  let className = "sidebar-field mb-2 select-none";

  const capitalized = title.charAt(0).toUpperCase() + title.slice(1);

  return <div key={key} className={className}>{capitalized}</div>;
}

function DraggableSidebarField(props) {
  const { title, index } = props;

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: title,
    data: {
      title,
      fromSidebar: true
    }
  });

  return (
    <div
      ref={setNodeRef}
      className="sidebar-field"
      {...listeners}
      {...attributes}
    >
      <SidebarField title={title} key={index} />
    </div>
  );
}

export default function Sidebar(props) {
  const { fieldsRegKey, titles } = props;
  
  return (
    <div key={fieldsRegKey} className="sidebar w-[250px]">
      {titles.map((title, i) => {
        return <DraggableSidebarField key={i} title={title} index={i} />

      }
      )}
    </div>
  );
}
