import { useState } from "react"

export function PortalNavbar(props) {
    let [activeTab, setActiveTab] = useState(0);
    let [menuOpen, setMenuOpen] = useState(false);

    function handleNavbarClick(i) {
        setActiveTab(i);
        if (menuOpen) {
            setMenuOpen(false);
        }
    }

    const menuItems = [
        { label: 'Tableau de bord', anchor: "#dashboard", index: 0 },
        { label: 'Annonces', anchor: '#dashboard', index: 1 },
        { label: 'Cours', anchor: "#course", index: 2 },
        { label: 'Forum', anchor: "#forum", index: 3 },
        { label: 'Espace personnel', anchor: "#personnal", index: 4 },
    ];

    return (
        <>
            {menuOpen && (
                <>
                    <ul className="fixed pt-20 flex flex-col items-start gap-4 text-sm bg-white backdrop-blur-[5px] p-4 w-full h-full z-40 top-0 left-0 right-0 bottom-0">
                        {menuItems.map(item => (
                            <a
                                key={item.index}
                                className={`pb-2 font-bold underline cursor-pointer hover:scale-105 hover:underline decoration-offset-8 transition duration-100 ${activeTab === item.index ? "text-black" : "text-black"}`}
                                onClick={() => handleNavbarClick(item.index)}
                            >
                                <a href={item.anchor}>{item.label}</a>
                            </a>
                        ))}
                        <div className="absolute top-5 right-5 flex-1" onClick={() => setMenuOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 rotate-45 text-red-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </div>
                        <div className=""><img src="/sparkso.png" className="absolute bottom-5 left-1/2 translate-x-[-50%]" width={50}/></div>
                    </ul>
                </>
            )}
            <header aria-label="Site Header" className="bg-blue-600 border relative">
                <div className="md:hidden absolute top-0 right-0">
                    <button onClick={() => setMenuOpen(!menuOpen)} className="p-4 bg-blue-600 text-white">
                        <span className="material-icons text-white bg-white">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="hidden md:block fixed top-0 h-[65px] w-screen bg-blue-600 shadow relative">
                    <a href="/login" className="text-white left-5 top-2 absolute block md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-4 h-4 mb-0.5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>Déconnexion
                    </a>
                    <ul className="flex items-center gap-6 text-sm max-w-[1310px] mx-auto flex-1 justify-center gap-16 items-center h-full relative">
                        {menuItems.map(item => (
                            <a
                                key={item.index}
                                className={`p-[1%] h-full flex items-center hover:underline cursor-pointer ${activeTab === item.index ? "bg-white text-black" : "bg-blue-600 text-white"}`}
                                onClick={() => handleNavbarClick(item.index)}
                                href={item.anchor}
                            >
                                {item.label}
                            </a>
                        ))}
                        <a href="/login" className="text-white uppercase font-bold underline absolute top-[50%] right-5 translate-y-[-50%]">Déconnexion</a>
                    </ul>
                </div>
                <a href="/" className="absolute top-5 left-4 text-white underline">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-4 h-4 mb-0.5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    Retour
                </a>
                <div className="p-4 md:p-8 mt-[65px] max-w-[1310px] mx-auto text-white">
                    <h1 className="font-bold text-xl md:text-3xl">
                        {props.name}
                    </h1>

                    <h3 className="mt-2 ">
                        {props.role}
                    </h3>
                </div>
            </header>
        </>
    )
}
