import { useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { useEffect, useState, useRef } from "react";

export default function Canvas({elements, setElements}) {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useDroppable({
    id: "canvas_droppable",
    data: {
      parent: null,
      isContainer: true,
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [editing, setEditing] = useState([]);
  const groupTitleRef = useRef(null);

  function toggleFullRow(index){
    const newElements = [...elements];
    newElements[index].fullRow = !newElements[index].fullRow;
    setElements(newElements);    
  }

  function removeGroup(index){
    const newElements = [...elements];
    newElements.splice(index, 1);
    setElements(newElements);
  }

  function toggleTableDisplay(index){
    const newElements = [...elements];
    newElements[index].table = !newElements[index].table;
    setElements(newElements);
  }

  function toggleFieldVisible(index, i){
    const newElements = [...elements];
    newElements[index].fields[i].hidden ? newElements[index].fields[i].hidden = false : newElements[index].fields[i].hidden = true;
    setElements(newElements);  
  }

  function toggleEditTitle(index){
    const oldEditing = [...editing];
    oldEditing[index] ? oldEditing[index] = false : oldEditing[index] = true;
    setEditing(oldEditing);
  }

  useEffect(() => {
    // console.log(elements);
  }, [elements]);

  return (
    <div
      ref={setNodeRef}
      className="canvas flex-1 h-auto border rounded-md border-black h-full overflow-hidden p-4"
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="canvas-fields flex flex-wrap justify-between">
        {elements && elements.map((group, index) => {
          return (
            <div className={`${group.fullRow ? "w-full" : "w-[48%]"} min-h-[200px] border rounded-md overflow-hidden mb-8 relative`}>
              <div className="bg-blue-200 flex items-center mb-4">
                {!editing[index] &&
                  <>
                    <div className="text-xl py-1 px-4 font-bold">{group.title.charAt(0).toUpperCase() + group.title.slice(1)}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer hover:scale-95" onClick={() => { toggleEditTitle(index) } }>
                      <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                    </svg>
                  </>
                }
                {editing[index] &&
                  <>
                    <input ref={groupTitleRef} type="text" defaultValue={group.title.charAt(0).toUpperCase() + group.title.slice(1)}></input>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer" onClick={() => {group.title = groupTitleRef.current.value; toggleEditTitle(index); }}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                  </>
                }
                
              </div>
              <div className="absolute top-2 right-5 flex gap-5">
                {group.fullRow ?
                  <svg onClick={() => { toggleFullRow(index)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                  </svg>
                  :
                  <svg onClick={() => { toggleFullRow(index)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                  </svg>
                }
                
                <svg onClick={() => {toggleTableDisplay(index)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                </svg>

                <svg onClick={() => {removeGroup(index)}}xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="w-6 h-6 cursor-pointer">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>

              </div>
              
              {group.table ? 
                <table className="border border-black mx-auto">
                  <thead>
                    <tr>
                      {group.fields.map((e) =>
                        <th className="border border-black px-2 py-1">{e.attribut3.charAt(0).toUpperCase() + e.attribut3.slice(1)}</th>
                      )}
                    </tr>
                  </thead>
                </table>
              :
                group.fields.map((e, i) => 
                  <div className="py-1 px-4 flex justify-between">
                    {e.hidden ?
                      <>
                        <div className="opacity-20">
                          {e.attribut3.charAt(0).toUpperCase() + e.attribut3.slice(1)}
                        </div>
                        <svg onClick={() => { toggleFieldVisible(index, i) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                      </>
                      : 
                      <>
                        <div>
                          {e.attribut3.charAt(0).toUpperCase() + e.attribut3.slice(1)}
                        </div>
                        <svg onClick={() => { toggleFieldVisible(index, i) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                      </>
                    }

                  </div>
                )
              }
            </div>
          );
        })}
      </div>
    </div>

    );
}
