import { EditableInformationBlocks } from "../components/editableInformationBlocks";
import { useState, useEffect, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import { getEmployeeData, getEmployeeJobs, getEmployeeDegrees, updateEmployeeData, updateEmployeeJob } from "../function";
import { uploadImage } from "../function";
import { InformationBlocks } from "../components/informationBlocks";
import { toast } from "react-toastify";
//@TODO  update degrees 

export default function EditEmployee() {
    console.log("bite");
    const [personnalData, setPersonnalData] = useState();
    const [contactData, setContactData] = useState();
    const [employeeJobsData, setEmployeeJobsData] = useState();
    const [lessonsData, setLessonsData] = useState();
    const [degreeData, setDegreeData] = useState();
    let { id } = useParams();
    const [image, setImage] = useState(null);

    useEffect(() => {
        getEmployeeData(id).then((data) => {
            console.log(data);
            setPersonnalData({ 'Nom': data.emLastname, 'Prenom': data.emfirstname, 'Date de naissance': data.emBirthdate, "Lieu de naissance": data.emBirthPlace, "Sexe": data.emSex, "CNI/Passeport": data.emPassport, "Date de délivrance": data.emPassportDeliveryDate, "Père": data.emFather, "Mère": data.emMother });
            setContactData({ "Téléphone": data.emPhone, "Email": data.emMail, "Adresse": data.emAddress });
            setLessonsData({ "Nombre de cours": data.emLessons, "Présent": data.emLessonsPresent, "Absent": data.emLessonsNotPresent, "Excusé": data.emLessonsExcuse })
            console.log(data.emLessons)
            console.log(lessonsData);
            console.log('lama')

            getEmployeeJobs(id).then((data) => {
                console.log(data)
                setEmployeeJobsData(data);
            })

            getEmployeeDegrees(id).then((data) => {
                console.log(data["records"])
                setDegreeData(data["records"]);
            })
        })
    }, [])

    const handleImageUpload = async (event) => {

        setImage(event.target.files[0]);
    };
    const updateData = () => {
        let lastName = document.getElementById('Nom').value
        let firstName = document.getElementById('Prenom').value
        let birthDate = document.getElementById('Date de naissance').value
        let birthplace = document.getElementById('Lieu de naissance').value
        let Sex = document.getElementById('Sexe').value
        let passeport = document.getElementById('CNI/Passeport').value
        let stPassportDeliveryDate = document.getElementById('Date de délivrance').value;
        let father = document.getElementById('Père').value;
        let mother = document.getElementById('Mère').value;
        let phoneNumber = document.getElementById('Téléphone').value;
        let email = document.getElementById('Email').value;
        let address = document.getElementById('Adresse').value;

        updateEmployeeData(id, firstName, lastName, birthDate, birthplace, Sex, passeport, stPassportDeliveryDate, father, mother, phoneNumber, email, address)
        const inputValues = [];

        for (let i = 0; i < inputRefs.current.length; i += 5) {
            const jobData = {
                emjJobId: employeeJobsData[i / 5].emjJobId,

                year: inputRefs.current[i].value,
                title: inputRefs.current[i + 1].value,
                company: inputRefs.current[i + 2].value,
                division: inputRefs.current[i + 3].value,
                description: inputRefs.current[i + 4].value,
            };
            inputValues.push(jobData);
        }

        inputValues.forEach((job) => {

            updateEmployeeJob(parseInt(id), parseInt(job.emjJobId), parseInt(job.year), job.title, job.company, job.division, job.description);

        });

        toast.info("Mise à jour effectuée !")
        setTimeout(() => {
            window.location.reload();
        }, 2000)

    }

    const imagesFormData = new FormData();
    imagesFormData.append("uId", id);
    imagesFormData.append("image", image);

    uploadImage(imagesFormData);

    const inputRefs = useRef(employeeJobsData ? Array(employeeJobsData.length * 5).fill(null) : []);

    useEffect(() => {
        if (employeeJobsData) {
            inputRefs.current = Array(employeeJobsData.length * 5).fill(null);
        }
    }, [employeeJobsData]);



    return (
        <>
            <div className="bg-[#44546a] w-full h-[150px] flex items-center relative">
                <div className="max-w-[1300px] mx-auto w-full flex items-center h-full">
                    <img src={`https://sparkso-universite.com:8081/api/uploads/${id}.png`} alt="" width="100" className="" />
                    <div className="flex-1">
                        <p className="text-white text-center text-3xl uppercase flex-1">
                            Université SPARKSO
                        </p>
                        <p className="text-gray-400 text-center text-xl">{personnalData && <>{personnalData["Prenom"]} <span className="capitalize">{personnalData["Nom"].toLowerCase()}</span></>}</p>
                    </div>
                </div>
                <Link to="/scan" className="absolute left-5 text-white bottom-7 underline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-4 h-4 mb-0.5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
Retour</Link>
            </div>

            <div className="w-fit m-auto block mt-6 mb-6">
                <p> Changer la photo de l'employé(e)</p>
                <input type="file" name='profilePic' onChange={handleImageUpload} />
            </div>



            <div className="flex gap-6 lg:w-[70%]  m-auto flex-col sm:flex-row">


                {/* <hr className=" h-[2px] bg-black w-[95%] text-center m-auto" /> */}

                {personnalData &&
                    <EditableInformationBlocks title='EMPLOYE(E)' personnalData={personnalData} />}

                {contactData &&
                    <EditableInformationBlocks title='Contact' contactData={contactData} />
                }

            </div>

            <div className="flex gap-6 lg:w-[70%]  m-auto flex-col sm:flex-row">


                {lessonsData &&
                    <InformationBlocks title='GESTION DES PRESENCES' lessonsData={lessonsData} />}

            </div>
            <div>


                <div className=" mt-2  flex-col sm:flex-row  w-[90%] lg:w-[70%] p-4  align-center justify-center items-center  border  m-auto mb-4 break-words " >
                    <div className="bg-[#bdd7ee]   ">
                        <p className="p-4 font-bold">
                            Parcours
                        </p>
                    </div>
                    {employeeJobsData && (
                        <div className="overflow-x-auto">
                            <table className="table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2">Année</th>
                                        <th className="px-4 py-2">Poste</th>
                                        <th className="px-4 py-2">Société</th>
                                        <th className="px-4 py-2">Division</th>
                                        <th className="px-4 py-2">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeJobsData.map((item, index) => (
                                        <tr key={index} id={item.emjJobId}>

                                            <td>
                                                <input type="text" defaultValue={item.emjJobYear} ref={(el) => inputRefs.current[index * 5] = (inputRefs.current[index * 5] || el)} className="border px-4 py-2" />

                                            </td>
                                            <td>
                                                <input type="text" defaultValue={item.emjJobTitle} ref={(el) => inputRefs.current[index * 5 + 1] = (inputRefs.current[index * 5 + 1] || el)} className="border px-4 py-2" />

                                            </td>
                                            <td>
                                                <input type="text" defaultValue={item.emjJobCompany} ref={(el) => inputRefs.current[index * 5 + 2] = (inputRefs.current[index * 5 + 2] || el)} className="border px-4 py-2" />

                                            </td>
                                            <td>
                                                <input type="text" defaultValue={item.emjJobDIvision} ref={(el) => inputRefs.current[index * 5 + 3] = (inputRefs.current[index * 5 + 3] || el)} className="border px-4 py-2" />
                                            </td>
                                            <td>
                                                <input type="text" defaultValue={item.emjJobDescription} className="w-[700px] border px-4 py-2" ref={(el) => inputRefs.current[index * 5 + 4] = (inputRefs.current[index * 5 + 4] || el)} />

                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex gap-6    m-auto flex-col sm:flex-row">

                {degreeData &&
                    <EditableInformationBlocks title='Diplomes' employeeDegreeData={degreeData} />
                }

            </div>

            <button onClick={updateData} className="border p-2 bg-red-600 rounded-md m-auto block ">
                Sauvegarder
            </button>
        </>
    )
}