import { getEmployeeData, getEmployeeDegrees, getEmployeeJobs } from "../function"
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { InformationBlocks } from "../components/informationBlocks";
import { Link } from 'react-router-dom';

export default function Employee() {
    const [personnalData, setPersonnalData] = useState();
    const [contactData, setContactData] = useState();
    const [employeeJobsData, setEmployeeJobsData] = useState();
    const [lessonsData, setLessonsData] = useState();
    const [degreeData, setDegreeData] = useState();
    const [connectedAs, setConnectedAs] = useState(null);

    let { id } = useParams();

    useEffect(() => {
        getEmployeeData(id).then((data) => {
            if (localStorage.getItem("accountType")) {
                setConnectedAs(localStorage.getItem("accountType"));
            }
            setPersonnalData({ 'Nom': data.emLastname, 'Prenom': data.emfirstname, 'Date de naissance': data.emBirthdate, "Lieu de naissance": data.emBirthPlace, "Sexe": data.emSex, "CNI/Passeport": data.emPassport, "Delivery Date": data.emPassportDeliveryDate, "Père": data.emFather, "Mère": data.emMother });
            setContactData({ "Téléphone": data.emPhone, "Email": data.emMail, "Adresse": data.emAddress });
            setLessonsData({ "Nombre de cours": data.emLessons, "Présent": data.emLessonsPresent, "Absent": data.emLessonsNotPresent, "Excusé": data.emLessonsExcuse })
            console.log(data.emLessons);
            console.log(lessonsData);

            getEmployeeJobs(id).then((data) => {
                console.log(data)
                setEmployeeJobsData(data);
            });

            getEmployeeDegrees(id).then((data) => {
                console.log(data["records"])
                setDegreeData(data["records"]);
            });
        });
    }, [])


    return (
        <>
            <div className="bg-[#44546a] w-full h-[150px] flex items-center relative mb-8 md:mb-0">
                <div className="max-w-[1300px] mx-auto w-full flex items-center h-full">
                    <img src={`https://sparkso-universite.com:8081/api/uploads/${id}.png`} alt="" width="100" className="" />
                    <div className="flex-1">
                        <p className="text-white text-center text-3xl uppercase flex-1">
                            Université SPARKSO
                        </p>
                        <p className="text-gray-400 text-center text-xl">{personnalData && <>{personnalData["Prenom"]} <span className="capitalize">{personnalData["Nom"].toLowerCase()}</span></>}</p>
                    </div>
                </div>
                <Link to="/scan" className="absolute left-5 md:text-white text-black z-40 -bottom-8 md:bottom-7 underline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-4 h-4 mb-0.5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>
Retour</Link>
            </div>
            <div className="max-w-[1400px] mx-auto flex flex-col md:flex-row">
                <div className="flex flex-col gap-6 lg:gap-24 lg:w-1/2 flex-col mt-4">
                    {personnalData &&
                        <InformationBlocks title='EMPLOYE(E)' personnalData={personnalData} id={id} />
                    }
                    {contactData &&
                        <InformationBlocks title='Contact' contactData={contactData} />
                    }
                </div>
                <div className="flex flex-col gap-6 lg:gap-24 lg:w-1/2  m-auto flex-col mt-4 w-full">
                    {degreeData &&
                        <InformationBlocks title='Diplomes' employeeDegreeData={degreeData} />
                    }
                </div>
            </div>
            <div className="max-w-[1400px] mx-auto mt-8  mb-24">
                {employeeJobsData &&
                    <InformationBlocks title='Parcours' jobsData={employeeJobsData} />
                }
            </div>
        </>
    )
}
