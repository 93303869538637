import { useEffect, useState } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { getDegree, getRecord } from "../function";

export default function Record({}){
    const navigate = useNavigate();
    const [datas, setDatas] = useState(null);
    let { id } = useParams();
    const classes = [
        "Mathématiques",
        "Philosophie",
        "Géographie",
        "Anglais",
        "Espagnol",
        "Physique Chimie",
        "Biologie",
        "Histoire",
        "Musique",
        "Sport"
    ];


    if(!id){
        navigate('/');
    }

    useEffect(() => {
        getRecord(id).then((response) => {
            if(!response.length){
                navigate('/');
            }
            const [record] = response;
            var datas = {...record};

            getDegree(id).then((response) => {
                if(!response.length){
                    navigate('/');
                }
                const [degree] = response;
                datas = {
                    ...datas,
                    ...degree
                };

                setDatas(datas);
            });
        });
    }, []);
    
    return (
        <>
        {datas &&
            <>
                <div className="bg-[#44546a] w-full h-[150px] text-white antialiased relative mb-12 md:mb-0">
                    <div className="max-w-[1300px] mx-auto flex items-center h-full">
                        <img src="/sparkso.png" width={100} className="mr-16 h-[50%] w-auto"/>
                        <div className="flex-1 max-h-full hidden md:flex">
                            <div className="w-1/2 h-full">

                                <div className="flex justify-between md:max-w-[70%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        Année:
                                    </div>
                                    <div className="">
                                        { id == 123 &&
                                            <div className="uppercase">2021/2022</div>
                                        }
                                        { id == 234 &&
                                            <div className="uppercase">2002/2003</div>
                                        }
                                        { id == 345 &&
                                            <div className="uppercase">2004/2005</div>
                                        }
                                    </div>
                                </div>

                                <div className="flex justify-between md:max-w-[70%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        Université:
                                    </div>
                                    <div className="">
                                        {datas.school}
                                    </div>
                                </div>
                                <div className="flex justify-between md:max-w-[70%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        Centre d'examen:
                                    </div>
                                    <div className="">
                                        {datas.exam_center}
                                    </div>
                                </div>
                                <div className="flex justify-between md:max-w-[70%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        Diplôme:
                                    </div>
                                    <div className="">
                                        {datas.degree_infos.degree_level}
                                    </div>
                                </div>
                                <div className="flex justify-between md:max-w-[70%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        ID Diplome:
                                    </div>
                                    <div className="relative">
                                        <a href={`/diplome/${id}`} className="underline">
                                            {id}
                                            <div className="absolute left-[calc(100%+10px)] top-0.5">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/2 h-full">

                                <div className="flex justify-between max-w-[60%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        Nom:
                                    </div>
                                    <div className="">
                                        {datas.student_infos.last_name}
                                    </div>
                                </div>

                                <div className="flex justify-between max-w-[60%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        Prénom:
                                    </div>
                                    <div className="">
                                        {datas.student_infos.first_name}
                                    </div>
                                </div>
                                <div className="flex justify-between max-w-[60%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        Date de naissance:
                                    </div>
                                    <div className="">
                                    {datas.student_infos.birthdate}
                                    </div>
                                </div>
                                <div className="flex justify-between max-w-[60%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        ID Étudiant:
                                    </div>
                                    <a href={`/?id=${datas.student_infos.id}`}>
                                        <div className="">
                                            {datas.student_infos.id}
                                        </div>
                                    </a>
                                </div>
                                <div className="flex justify-between max-w-[60%] mb-1">
                                    <div className="font-medium text-[16px]">
                                        ID relevé de notes:
                                    </div>
                                    <div className="relative">
                                        {id}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => navigate(-1)} className="cursor-pointer absolute left-5 text-black md:text-white -bottom-8 md:bottom-7 underline"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline w-4 h-4 mb-0.5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                        Retour
                    </div>
                </div>
                <div className="max-w-[90%] md:max-w-[70%] mx-auto mt-2">
                    <div className="flex md:hidden gap-6 flex-col md:flex-row">
                        <div className="flex border border-1 rounded-md w-full flex-col mx-auto mb-4">
                            <div className="bg-[#bdd7ee] w-full font-bold p-4">
                                <div className="flex-1 max-h-full flex flex-col">
                                    <div className="w-full h-full text-right">
                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                Année:
                                            </div>
                                            <div className="ml-2">
                                                { id == 123 &&
                                                    <div className="uppercase">2021/2022</div>
                                                }
                                                { id == 234 &&
                                                    <div className="uppercase">2002/2003</div>
                                                }
                                                { id == 345 &&
                                                    <div className="uppercase">2004/2005</div>
                                                }
                                            </div>
                                        </div>

                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                Université:
                                            </div>
                                            <div className="ml-2">
                                                {datas.school}
                                            </div>
                                        </div>
                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px] truncate">
                                                Centre d'examen:
                                            </div>
                                            <div className="ml-2">
                                                {datas.exam_center}
                                            </div>
                                        </div>
                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                Diplôme:
                                            </div>
                                            <div className="">
                                                {datas.degree_infos.degree_level}
                                            </div>
                                        </div>
                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                ID Diplome:
                                            </div>
                                            <div className="relative">
                                                <a href={`/diplome/${id}`} className="underline mr-6">
                                                    {id}
                                                    <div className="absolute right-0 top-0.5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                        </svg>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full h-full">

                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                Nom:
                                            </div>
                                            <div className="ml-2">
                                                {datas.student_infos.last_name}
                                            </div>
                                        </div>

                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                Prénom:
                                            </div>
                                            <div className="ml-2">
                                                {datas.student_infos.first_name}
                                            </div>
                                        </div>
                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                Date de naissance:
                                            </div>
                                            <div className="ml-2">
                                            {datas.student_infos.birthdate}
                                            </div>
                                        </div>
                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                ID Étudiant:
                                            </div>
                                            <a href={`/?id=${datas.student_infos.id}`}>
                                                <div className="">
                                                    {datas.student_infos.id}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="flex justify-between max-w–[100%] mb-1">
                                            <div className="font-medium text-[16px]">
                                                ID relevé de notes:
                                            </div>
                                            <div className="relative">
                                                {id}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-6">
                        <div className="flex border border-1 rounded-md w-full flex-col mx-auto">
                            <div className="bg-[#bdd7ee] w-full font-bold p-4">
                                INFORMATIONS
                            </div>
                            <div className="flex justify-between">
                                <div className="w-[360px] lg:w-[430px]">
                                    <div className="">
                                        <div className="flex justify-between mx-auto p-4">
                                            <div className="font-medium">
                                                Statut
                                            </div>
                                            <div className={`uppercase ${datas.status == "REFUSÉ" ? "text-red-600 font-bold" : ""}`}>{datas.status}</div>
                                        </div>
                                        <div className="flex justify-between mx-auto p-4">
                                            <div className="font-medium">
                                                Moyenne
                                            </div>
                                            <div className="uppercase">{
                                                datas.results.reduce((a, b) => a + b.note, 0) / datas.results.length
                                            }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[360px] lg:w-[430px]">
                                    <div className="flex justify-between mx-auto p-4">
                                        <div className="font-medium">
                                            Session
                                        </div>
                                        <div className="uppercase">{datas.session}</div>
                                    </div>
                                    <div className="flex justify-between mx-auto p-4">
                                        <div className="font-medium">
                                            Année d'obtention du diplôme
                                        </div>
                                        { id == 123 &&
                                            <div className="uppercase">2021/2022</div>
                                        }
                                        { id == 234 &&
                                            <div className="uppercase">2002/2003</div>
                                        }
                                        { id == 345 &&
                                            <div className="uppercase">2004/2005    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-[90%] md:max-w-[70%] mx-auto mt-2">
                    <div className="flex gap-6 w-full rounded-[10px] overflow-hidden">
                        <table className="w-full border border-1 mb-4">
                            <thead className="font-bold uppercase">
                                <td className="p-4 bg-[#bdd7ee]">Matières</td>
                                <td className="p-4 bg-[#bdd7ee] text-center">Notes</td>
                                <td className="p-4 bg-[#bdd7ee] text-center">Barème</td>
                                <td className="p-4 bg-[#bdd7ee] text-center">Coefficient</td>
                            </thead>
                            <tbody  className="">
                                {datas.results.map((result, i) => {
                                    return (
                                    <tr>
                                        <td className="pt-4 px-4">{classes[i]}</td>
                                        <td className="pt-4 px-4 text-center">{result.note}</td>
                                        <td className="pt-4 px-4 text-center">{result.maxnote}</td>
                                        <td className="pt-4 px-4 text-center">{result.ratio}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                        {console.log(datas.results)}
                    </div>
                </div>
            </>
        }
        </>
    );
}