import { useEffect, useState, useRef, createRef } from "react";

export default function ImportDatas(){

    const [tables, setTables] = useState(null);
    const [sql, setSql] = useState([]);
    const inputsRef = useRef([]);

    useEffect(() => {
        fetchTables();
    }, []);

    async function fetchTables(){
        const response = await fetch('https://sparkso-universite.com:8081/api/getDatas', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dbName: "IUGM" // TODO: Get this programmatically
            })
        }).then((response) => {
            return response.json();
        });

        const tables = [];
        await response.mapping.forEach((e) => {
            if (!tables.includes(e.attribut4)) {
                tables.push(e.attribut4);
            }
        });
    
        setTables(tables);
    }

    async function onFileSelected(event, index){
        console.log(event.target);
        if(event.target.files[0]){
            const file = event.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            const response = await fetch(fileUrl);
            const text = await response.text();
            const lines = text.split("\n");
            const _data = lines.map((line) => line.split(","));
            
            _data.forEach((line) => {
                if (line != "") {
                    var oldSql = sql;
                    line = line.map((e) => {
                        if (e == '')  return ('""');
                        return e;
                    });

                    oldSql[index] ? oldSql[index] += `INSERT INTO Table${(index + 1)} VALUES (${line.join(',')});` : oldSql[index] = `INSERT INTO Table${(index + 1)} VALUES (${line.join(',')});`;
                    setSql(oldSql);
                }
            });
        }
    }

    async function submit(){
        await fetch("https://sparkso-universite.com:8081/api/insertDb", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sql: sql 
            })
        }).then((response) => 
            response.json()
        ).then((datas) => {
            console.log(datas);
        })
    }

    return (
        <div className="p-4">
            <div className="text-black text-center mb-8 uppercase">IMPORTER LES DONNéES</div>
            <div className='flex justify-between items-center mb-8'>
                {/* <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={onFileSelected}/>
                <input type="text" className="border border-gray-500 rounded-md w-[500px] px-4 py-1" placeholder="Entrez le nom de votre Base de données" defaultValue={"IUGM"}></input> */}
                <button className="bg-red-600 text-white w-[200px] h-[40px] rounded-md" >Supprimer</button>
                <button className="bg-green-600 text-white w-[200px] h-[40px] rounded-md" onClick={submit}>Valider</button>
            </div>

            <div className="text-center w-full">

                {tables && tables.map((e, i) => {
                        inputsRef.current = tables.map((e, i) => inputsRef.current[i] ?? createRef());

                        return (
                            <div className="mb-2 flex justify-center"><div className="w-[300px]">{e}</div><input onChange={(event) => { onFileSelected(event, i) }} type="file"></input></div>
                        );
                    })
                }
            </div>
        </div>
    );
}