import { useRef, useState } from 'react';

export default function NewStudent(){
    const [created, setCreated] = useState(false);
    const nameRef = useRef(null);
    const surnameRef = useRef(null);
    const birthdateRef = useRef(null);

    async function submit(e){
        e.preventDefault();
        const name = nameRef.current.value;
        const surname = surnameRef.current.value;
        const birthdate = birthdateRef.current.value;

        await fetch("https://sparkso-universite.com:8081/api/createStudent", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": name,
                "surname": surname,
                "birthdate": birthdate
            })
        }).then((response) => 
            response.json()
        ).then((datas) => {
            if (datas == "ok") {
                setCreated(true);
            }
        });
    }

    return (
        <div className="p-4">
            <div className="text-2xl mb-2">Créer un étudiant</div>
            {created && 
                <div className="text-green-600">Étudiant créé</div>
            }
            <form onSubmit={submit}>
                <div className="flex mb-2">
                    <label className="w-[200px]">Nom</label>
                    <input ref={nameRef} type="text" className="border border-black"></input>
                </div>
                <div className="flex mb-2">
                    <label className="w-[200px]">Prénom</label>
                    <input ref={surnameRef} type="text" className="border border-black"></input>
                </div>
                <div className="flex mb-2">
                    <label className="w-[200px]">Date de naissance</label>
                    <input ref={birthdateRef} type="text" className="border border-black"></input>
                </div>

                <input type="submit" value="Valider" className="bg-green-500 px-2 py-1 rounded-md"></input>
            </form>
        </div>
    )
}